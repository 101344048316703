const en = {
	message: {
		common: {
			fieldRequired: '*This field is required',
			tempErrorMessage: 'We are currently experiencing some technical difficulties with our infrastructure. Therefore, deck generation is currently halted. The learning experience is not affected by this outage. Our team is actively investigating the issue and working to resolve it as quickly as possible. A big sorry for the inconvenience.',
			studyflashTeam: "Your Studyflash team."
		},
		header: {
			seeWhatsNew: 'Suggest a new feature',
			tokensLeft: ' Tokens left',
			premiumUser: 'Premium active!',
			basicUser: 'Basic user',
		},
		signInPage: {
			heading: 'Welcome!',
			subtitle:
				'Ready for the study revolution? Create an account or log in to save time while studying!',
			haveAccount: 'Already have an account?',
			continueWithGoogle: 'Continue with Google',
			continueWithApple: 'Continue with Apple',
			continueWithCredentials: 'Continue with email',
			signIn: 'Sign in',
			or: 'or',
			email: 'Email',
			password: 'Password',
			passwordLengthMessage: 'Your password needs to be atleast 8 characters long',
			passwordConfirmation: 'Password confirmation',
			tos1: "By signing up, you agree to Stydflash's ",
			tos2: 'Terms of Service',
			tos3: 'and',
			tos4: 'Privacy Policy',
			tos5: '',
			newToStudyflash: 'New to Studyflash?',
			rememberMe: 'Remember me',
			forgotPassword: 'Forgot password',
			checkEmail: 'Check your email',
			checkEmailMessage1: "We've sent an email to",
			checkEmailMessage2: 'to activate your account.',
			emailAlreadyExists: 'This email was already used. Sign in or use another email to sign up.',
			unsuccessfulLogin: 'Invalid credentials, please try again',
			passwordMismatch: "Passwords don't match",
			tosAgreementMissing: 'Please agree to the terms of service and privacy policy',
			deletedEmailReused: 'This email is already in use, please use another email to sign up.',
			newPlatformResetPasswordMessage:
				'Please check your email for a password reset link to be able to log in to the new platform',
			accPermanentlyDeleted: "This account has been deleted permanently as of ",
			accTemporaryDeleted1: "This account has been deleted as of",
			accTemporaryDeleted2: ", check your email for the reactivation link!",
		},
		studyPage: {
			buttonAgain: 'Again',
			buttonHard: 'Hard',
			buttonGood: 'Good',
			dontShowAgain: "Don't show again",
			buttonEasy: 'Easy',
			buttonEdit: 'Edit',
			buttonFinish: 'Finish',
			buttonPrevious: 'Previous',
			showAnswer: 'Show answer',
			done: "You're done with your flashcards in this Deck!",
			sessionDone: "You're done with this study session!",
			learnOther: 'Learn other flashcards!',
			finishSession: 'Finish study session',
			deleteButton: 'Delete',
			saveButton: 'Save',
		},
		leftNav: {
			createNewFlashcards: 'Create new flashcards',
			homeButton: 'Home',
			decksButton: 'Decks',
			tokensButton: 'Subscriptions',
			feedbackButton: 'Help',
			inviteFriendText1: 'Invite friends and',
			inviteFriendText2: 'get one month premium for free!',
			inviteFriendButton: 'Invite a friend',
			logout: 'Log out',
			noDecksToast: 'You have not yet created a deck, try creating one first!',
		},
		dashboard: {
			dashboard: 'Dashboard',
			studyDaily: 'Study time today',
			studyWeekly: 'This week’s study time',
			cardReview: 'Flashcard review',
			studyTimeToday: 'Study time today',
			studyingFor1: "You've been studying for",
			studyingFor2: 'in a row',
			day: 'day',
			days: 'days',
			cards: 'cards',
			study: 'Study',
			yesterday: 'Yesterday',
			tomorrow: 'tomorrow',
			today: 'today',
			toContinue: 'to continue the streak',
			cardsStudiedToday: 'Cards studied today',

			// Flashcard table
			createNewFlashcards: 'Create new flashcards',
			flashcardReview: 'Flashcard Review',
			deckName: 'Deck Name',
			new: 'New',
			learn: 'Learn',
			due: 'Due',
			noDecks: "You haven't created any flashcards yet",
			studyAllButton: 'Study all',
			created: 'Created',
			flashcards: 'Flashcards',
		},
		settings: {
			settings: 'Settings',
			closeButton: 'Close',
			account: 'Account',
			subscription: 'Subscription',
			tokens: 'Tokens',
			personalInfo: 'Personal information',
			language: 'Language',
			balance: 'Balance',
			tokensCaps: 'Tokens',
			deleteMyAccount: 'Delete my account',
			deleteAccountConfirmation1: 'Do you really want to delete your account?',
			deleteAccountConfirmation2: 'It will be gone forever (and that’s a long time).',
			delete: 'Delete',
			discard: 'Discard',
			subscriptionTitle: 'Subscription',
			currentSubscription: 'Current subscription',
			renewalDate: 'Renewal date',
			inactive: 'None',
			noSubFound: 'No subscription found',
			monthly: 'Monthly',
			quarterly: 'Quarterly',
			yearly: 'Yearly',
			manageSubscriptions: 'Manage subscriptions',
			legacyTokensText:
				'Tokens are a legacy feature, you can still use them but not purchase new ones. The tokens remain valid for one year after the purchase.',
			more: "More",
			deleteAccount: "Delete Account",
			deleteAccountText: "Delete your account and your personal information.",
			deleteAccountText1: "This action is not reversible.",
			submit: "Submit"
		},
		feedback: {
			submitFeedback: 'Help',
			title: 'Title',
			description: 'Description',
			attachImages: 'Attach images',
			dismiss: 'Dismiss',
			submit: 'Submit',
			feedbackSuccess: "Feedback successfully submitted, we will get back to you ASAP!"
		},
		onboardingPage: {
			welcome: 'WELCOME',
			happyToHaveYou: 'Happy to Have you on board',
			countryQuestion: 'In which country are you studying?',
			choseOption: 'Choose an option',
			languageQuestion: 'Select your preferred language',
			universityQuestion: 'Where do you study?',
			majorQuestion: 'What are you studying?',
			studyMajorOptions: {
				business: 'Business & Economics',
				social_sciences: 'Social Sciences (e.g., Psychology, Sociology)',
				humanities: 'Humanities (e.g., History, Languages, Philosophy)',
				law: 'Law',
				medicine: 'Medicine',
				science: 'Science (e.g., Biology, Chemistry, Mathematics)',
			},
			otherField: 'Other',
			startDate: 'When did you start your studies?',
			startDateOptions: {
				fall2018: 'Fall Semester 2018',
				spring2019: 'Spring Semester 2019',
				fall2019: 'Fall Semester 2019',
				spring2020: 'Spring Semester 2020',
				fall2020: 'Fall Semester 2020',
				spring2021: 'Spring Semester 2021',
				fall2021: 'Fall Semester 2021',
				spring2022: 'Spring Semester 2022',
				fall2022: 'Fall Semester 2022',
				spring2023: 'Spring Semester 2023',
				fall2023: 'Fall Semester 2023',
				spring2024: 'Spring Semester 2024',
				fall2024: 'Fall Semester 2024',
			},
			heardAboutUsQuestion: 'How did you get to Studyflash?',
			heardAboutUsOptions: {
				instagram_influencer: "Instagram (Creator)",
				tiktok_influencer: "TikTok (Creator)",
				tiktok_studyflash: 'TikTok (Studyflash)',
				instagram_studyflash: 'Instagram (Studyflash)',
				word_of_mouth: 'Friends (Word of Mouth)',
				search_engines: 'Search Engines (e.g., Google search results)',
				linkedin: 'Linkedin',
				other: 'Other',
			},
			birthDayQuestion: "When's your birthday? (optional)",
			referralCode: 'Referral code (optional)',
			validReferralCode: 'Referral code will be applied',
			invalidReferralCode: 'Invalid referral code. Please check and try again.',
			toc1: "By signing up, you agree to Studyflash's ",
			toc2: 'Terms of Service',
			toc3: ' and ',
			toc4: 'Privacy Policy',
			toc5: '',
			tocText1:
				"Stay ahead of the curve! Join our student community for the latest updates, exclusive resources, and opportunities. Your privacy matters — we'll use your data in accordance with our ",
			tocText2: 'Privacy Policy',
			tocText3: ". Remember, you're in control, and unsubscribing is hassle-free.",
			signIn: 'Sign in',
			agreeToTos: "Please agree to the terms of service",
			allFieldsRequired: "Please fill out all required fields",
			highSchool: "High school"
		},
		decksPage: {
			deckOverview: 'Deck Overview',
			organizeText: 'Organize, edit, and study your decks all in one place',
			basicButton: 'Cram deck',
			restoreButton: 'Deleted Decks',
			createButton: 'New folder',
			customStudySessionsTitle: 'Ongoing Cramming Sessions',
			newStudySessionTitle: "Cram a deck",
			decksTitle: 'Decks',
			infoPopupTitle: 'Basic 🎓',
			infoPopupText:
				'In cram mode, you can repeat your flashcards as often as you like. This is ideal if you want to repeat all the flashcards again a few days before the exam.',
			infoPopupLabelDeck: 'Choose deck',
			start: 'Start',
			cancel: 'Cancel',
			createNew: "Create new session",
			createNewFlashcard: 'Create a new flashcard',
			freeDeckTip:
				'*This is a trial AI generated deck, the number of cards is limited to 50. For unlocking the full functionality please refer to ',
			subscriptionPage: 'the subscription page.',
			saveButton: 'Save',
			preventCircularDependency: "Deck can not be a child of its descendant.",
			generationMightTakeLonger: "Note: Flashcard creation may take longer with the image feature",
			noDeck: "This deck doesn't exist!"
		},
		createPopup: {
			newFolder: 'New Folder',
			folderLevel: 'Folder level (optional)',
			placeholder: 'Economics',
			folderName: 'Folder Name',
			explanation: 'This will create a new subfolder inside the folder selected above.',
			cancel: 'Cancel',
			create: 'Create',
		},
		deckPage: {
			rateDeck: 'Rate deck',
			updateRating: 'Update rating',
			allDecks: 'All decks',
			study: 'Study',
			addCard: 'Add card',
			addNewCard: 'Add new card',
			deckEmpty: 'Ooops, seems like this deck is empty... Try adding new cards! 😄',
			openEnded: 'Open-ended',
			editCard: 'Edit Flashcard',
			tip: 'AI is not yet perfect and can occasionally make mistakes. We therefore recommend that you briefly check the generated flashcards.',
			question: 'Question',
			answer: 'Answer',
			save: 'Save',
			search: 'Search by keyword',
			areYouSure: 'Are you sure you want to delete this card?',
			confirm: 'Confirm',
			cancel: 'Cancel',
			confirmDeletion: 'Confirm card deletion',
			creatingFlashcardsPopupMessage1: "Don't worry we're on it! 🥰",
			creatingFlashcardsPopupMessage2:
				"This process might take a moment ⌛, but it's all to ensure you have a seamless and effective study experience. Thanks for your patience while we crunch the data and create your personalized flashcards. 📚🧠",
			creatingFlashcardsPopupTitle: 'Preparing Your Flashcards 🔎',
			creatingFlashcardsPopupClose: 'Got it, thanks!',
			unlockCards: 'Unlock all flashcards',
			failedGenerating1: 'Oops!🙁',
			failedGenerating2:
				'We’ve given it our all, but we couldn’t whip up flashcards for every page. Don’t worry, though, you might want to give the following pages a peek and consider adding the flashcards manually:',
			questionAndAnswerRequired: "Please provide a question and an answer for your new flashcard!",
			cardTip1: "<b>Tip:</b> You can still manually create decks and flashcards",
			cardTip2: "<b>Tip:</b> You can still study your flashcards"
		},
		explanationPopup: {
			spacedRepetition: 'Spaced repetition',
			text1: 'Hi there,',
			text2:
				"To help you achieve the best results with Studyflash, we'd like to briefly explain how and why our study tool works:",
			text3:
				"Our tool relies on the same algorithms and principles as Anki, if you're familiar with Anki, feel free to skip this introduction.",
			li11: 'When going through the flashcards, try to ',
			li12: 'actively recall',
			li13: " the answer, and then check your response by flipping the card. Actively retrieving information engages the brain in a way that's much more effective for reinforcing memory than passive reading or reviewing.",
			li21: "After recalling the answer, categorize how difficult it was for you to answer the question. Based on this classification, our tool will determine the interval at which you'll see this flashcard again. This ",
			li22: 'spaced repetition',
			li23: " helps you remember things better by reminding you just when you're about to forget them.",
			gotIt: 'Got it, thanks!',
		},
		createPage: {
			createNewDeck: 'Create New Flashcard Deck',
			inputSlides: 'Input your lecture slides and experience the magic!',
			reminder:
				'Reminder: Studyflash relies on large language models, which are limited in terms of image and formula processing',
			folder: 'Folder',
			placeholder: 'Economics',
			deck: 'Deck name',
			outputLanguage: 'Output Language',
			en: 'English',
			de: 'German',
			fr: 'French (only works for french files)',
			sp: 'Spanish (only works for spanish files)',
			createFor: 'Create for ',
			tokens: ' tokens',
			createSubscription: 'Generate deck!',
			costMultiplication1: "Uh-oh, your document is hefty. We've multiplied tokens by ",
			costMultiplication2: 'x to support our costs.',
			beingProcessed:
				'Your slides are being processed! You will start seeing results in a brief moment.',
			drag: 'Drag & Drop or',
			choseFile: 'Choose File',
			upload: 'to upload',
			supportedTypes: 'Accepted file types: PDF, DOCX, PPTX.',
			fileUploaded: 'Your file was uploaded',
			cantPreview: "(Can't preview on mobile 😞)",
			previewNotAvailable: 'Preview only available for PDFs',
			wrongFileType: "That is not a supported file type, please provide a PDF, DOCX or PPTX file.",
			subscriptionNeeded: "You need to subscribe to generate flashcards.",
			imagesOnFlashcards: "Include images in flashcards"
		},
		referral: {
			inviteFriend: 'Invite a friend',
			description:
				'Help spread the word about Studyflash. Your friend gets a 20% discount, and you receive one month of Studyflash premium for free after they make a subscription.',
			copyLink: 'Copy Link',
			linkCopied: 'Link Copied!',
			copied: 'Copied!',
		},
		undoDelete: {
			card: 'Card successfully deleted!',
			deck: 'Deck successfully deleted!',
			undo: 'Undo',
		},
		deckBin: {
			deckBin: 'Deck Bin',
			subtitle: 'View and retrieve recently deleted decks',
			tip: "Tip: Restoring the deck will automatically restore it's child decks and all the cards in them!",
			name: 'Name:',
			deletedAt: 'Deleted on:',
			allDecks: 'All decks',
		},
		customStudySessionItem: {
			areYouSure: 'Are you sure you want to delete this study session?',
			confirm: 'Confirm',
			cancel: 'Cancel',
			confirmDeletion: 'Confirm study session deletion',
		},
		deckItem: {
			areYouSure: 'Are you sure you want to delete?',
			confirm: 'Confirm',
			cancel: 'Cancel',
			confirmDeletion: 'Confirm deck deletion',
		},
		welcomeMessage: {
			title: 'Welcome to Studyflash! 🚀',
			thrilled: "We're thrilled to have you here! 🎉",
			text1: 'To kickstart your Studyflash journey, your ',
			text2: 'first creation is on us:',
			text3:
				' transform your lecture slides into scientifically optimized flashcards. Explore a smart and efficient way to study now!',
			tryFree: 'Try for free!',
		},
		newsLetterOptIn: {
			subscriptionSuccessful: 'Subscription successful!',
			subscriptionText:
				"Welcome aboard! You're now subscribed to our newsletter, you will be hearing from us soon.",
			proceedButton: 'Proceed',
		},
		subscriptionShop: {
			title: 'The AI study revolution is here!',
			subtitle:
				'Get unlimited* access to all Studyflash tools with our flexible subscriptions. Create flashcards for a flat fee, saving countless hours during your exam phase',
			month1: 'Month',
			tos: '*There’s a fair usage policy in place to prevent abuse of the platform. Should you reach the limit you can reach out to us.',
			free: {
				billed: 'Try it for free!',
				title: 'FREE TRIAL',
				text: 'First deck completely for free. No card needed.',
				subscribeText: 'Try it out',
			},
			month: {
				billed: 'Billed monthly',
				title: 'MONTHLY SUBSCRIPTION',
				text: 'Automatic renewal. Cancelable at any time.',
				subscribeText: 'Subscribe monthly',
			},
			semester: {
				billed: 'Billed quarterly',
				title: 'SEMESTERLY SUBSCRIPTION',
				text: 'Automatic renewal. Three-month commitment.',
				subscribeText: 'Subscribe semesterly',
			},
			year: {
				billed: 'Billed annually',
				title: 'YEARLY SUBSCRIPTION',
				text: 'Automatic renewal. Cancelable annually.',
				subscribeText: 'Subscribe yearly',
			},
			fairUsage1: '*There’s a ',
			fairUsage2: 'fair usage policy',
			fairUsage3:
				' in place to prevent abuse of the platform. Should you reach the limit you can reach out to us.',
			discountTip: 'This discount is valid only for the first billing cycle!',
			validCode: 'Applied discount code for ',
			invalidCode: 'Not a valid discount code!',
			applyCode: 'Apply Code',
			discountPlaceholder: 'Enter a discount code',
			referralDiscountApplied: 'Referral discount applied',
		},
		errorBoundary: {
			title: 'It seems that there was an error handling your request.',
			p11: 'Please try ',
			p12: 'reloading the page ',
			p13: 'or ',
			p14: 'signing out.',
			p21: 'Should this not help, you can always ',
			p22: 'reach out to us.',
		},
		errorCodes: {
			general: {
				unhandledPromise: 'Error code: UNPR01',
				createEmptyDeckFailed:
					'There was an error creating an empty deck, please try again! Error code: POP03',
				undoCardDelete:
					'There was an error restoring the flashcard, please try again! Error code: POP01',
				undoDeckDelete:
					'There was an error restoring the deck, please try again! Error code: POP02',
				feedbackFailed:
					'There was an error submitting the feedback, please try again! Error code FE01',
				deckRatingFailed:
					'There was an error rating the deck, please try again! Error code POP04',
				npsRatingFailed:
					'There was an error saving your rating, please try again! Error code POP05',
			},
			dashboard: {
				cardCountingFailed: "There was an error while counting the cards in your decks, sorry for the inconvenience. Error code: DA01",
			},
			decks: {
				deleteFailed: "There was an error deleting the deck, please try again! Error code: DE01",
				creatingCustomSessionFailed: "There was an error creating the custom study session, please try again! Error code: DE02",
				insertingCardsIntoCustomSessionFailed: "There was an error creating your learning session, please try again! Error code: DE03",
				updateFailed: "There was an error updating the deck, please try again! Error code: DE04",
				fetchCustomSessionsFailed: "There was an error fetching your custom study sessions, please try again! Error code DE05"
			},
			deck: {
				createFlashcardFailed: 'There was an error creating a new flashcard. Error code: DEOW01',
				createFlashcardFailedMobile:
					'There was an error creating a new flashcard. Error code: DEOW02',
				updateFailed1: 'There was an error updating the deck, please try again! Error code DEOW03',
				updateFailed2: 'There was an error updating the deck, please try again! Error code DEOW04',
				fetchFailed1:
					'There was an error fetching your flashcards, please try again! Error code DEOW05',
				fetchFailed2:
					'There was an error fetching your flashcards, please try again! Error code DEOW06',
				cardUpdateFailed1:
					'There was an error updating your flashcard, please try again! Error code DEOW07',
				cardUpdateFailed2:
					'There was an error updating your flashcard, please try again! Error code DEOW08',
				searchFailed1:
					'There was an error searching for your flashcards, please try again! Error code DEOW09',
				searchFailed2:
					'There was an error searching for your flashcards, please try again! Error code DEO10',
				deleteFailed1:
					'There was an error deleting your flashcard, please try again! Error code DEOW11',
				deleteFailed2:
					'There was an error deleting your flashcard, please try again! Error code DEOW12',
				countFailed:
					'There was an error counting your flashcards, please try again! Error code DEOW13',
			},
			customStudy: {
				deleteFailed: 'There was an error deleting the custom study session. Error code: CSS01',
				cardUpdateFailed1:
					'There was an error updating the card, please try again! Error code: CSS02',
				cardUpdateFailed2:
					'There was an error updating the card, please try again! Error code: CSS03',
				showPreviousFailed:
					'There was an error showing the previous card, please try again! Error code: CSS04',
				saveChangesFailed:
					'There was an error saving the changes, please try again! Error code: CSS05',
				removeSessionFailed: 'There was an error removing the completed session. Error code: CSS06',
				initFailed1: 'There was an error initializing the custom study session. Error code CSS07',
				initFailed2: 'There was an error initializing the custom study session. Error code CSS08',
			},
			settings: {
				localeChangeFailed: "There was an error updating locale setting, please try again! Error code: SET01",
				accountDeleteFailed: "There was an error deleting your account, please try again! Error code: SET02",
				emailUpdateFailed: "There was an error updating your email address, please try again! Error code: SET03",
				emailUpdateFailedMobile: "There was an error updating your email address, please try again! Error code: SET04",
				subscriptionFetchFailed: "There was an error getting subscription details, please try again! Error code: SET05",
				subscriptionUpdateFailed: "There was an error updating subscription details, please try again! Error code: SET06",
				updateProfilePhotoFailed: "There was an error updating your avatar, please try again! Error code: SET07",
				manageSubscriptionsFailed: "There was an error managing your subscription, please try again! Error code: SET08"
			},
			purchase: {
				redirectStripeFailed:
					'There was an error redirecting to Stripe, please try again! Error code: SH01',
				referralCodeFailed:
					'There was an error verifying your referral code, please try again! Error: code SH01',
				discountCodeFailed:
					'There was an error verifying your discount code, please try again! Error: code SH02',
			},
			create: {
				fileUploadFailed:
					'There was an error uploading your file, please try again! Error code: CR01',
				fileAccessFailed:
					'There was an error accessing the uploaded file, please try again!  Error code: CR02',
				createDeckFailed: 'There was an error creating a new deck. Error code: CR03',
				cardGenerationFailed:
					'There was an error generating cards, please try again! Error code: CR04',
				costCalculationFailed:
					'There was an error calculating token cost, please try again! Error code: CR05',

			},
			bin: {
				fetchingFailed:
					'There was an error getting deleted decks, please try again! Error code: DB01',
				restoringFailed: 'There was an error restoring decks, please try again! Error code: DB02',
				checkingIsParentDeletedFailed: 'There was an error, please try again! Error code: DB03',
			},
			onboarding: {
				updateProfileFailed:
					'There was an error updating your profile, please try again! Error code: ON02',
				newsletterFailed: 'There was an error with the newsletter subscription. Error code: ON01',
			},
			study: {
				fetchFailed: 'There was an error getting flashcards, please try again! Error code: ST02',
				progressSessionCreationFailed:
					'There was an error tracking progress, but you may continue studying. Error code: ST01',
				flashcardHandlingFailed1:
					'There was an error handling flashcard, please try again! Error code: ST05',
				flashcardHandlingFailed2:
					'There was an error handling flashcard, please try again! Error code: ST06',
				saveChangesFailed: 'There was an error saving changes, please try again! Error code: ST03',
				progressSessionUpdateFailed:
					'There was an error tracking your progress, sorry for the inconvenience. Error code: ST04',
				cardDeletionFailed:
					'There was an error deleting the card, please try again! Error code: ST07',
			},
			router: {
				accountAccessError: "There was an error with the authentication provider, sorry for the inconvenience, please just log in again!"
			},
			deckSharing: {
				urlCreationFailed: "There was an error sharing the deck, please try again! Error code: DS01",
				urlCopied: "Invite URL copied to clipboard!",
				urlCopyFailed: "Could not copy URL to clipboard, but you can still copy it manually!"
			}
		},
		deckFeedback: {
			welcome: "Hi there, thank you for trying out Studyflash! We’d love to hear your feedback to be able to improve the Studyflash experience for all of us.",
			rateQuality: "Please rate the overall quality of the flashcards created by the platform.",
			unusable: "Unusable",
			excellent: "Excellent quality",
			additionalFeedback: "Could you tell us what you did (not) like about the flashcards?",
			typeHere: "Type here...",
			submit: "Submit",
			cancel: "Cancel"
		},
		generalFeedback: {
			ratingGood: "Extremely likely",
			ratingBad: "Not likely at all",
			question: "How likely are you to recommend Studyflash to a friend?",
			why: "Why?",
			tellUsMore: "Tell us a bit more so that we can improve Studyflash further 🤝",
			yourAnswerHere: "Your answer here...",
			detractorText: "😢 Could you tell us more about your score to help us improve?"
		},
		tour: {
			welcomeMessage: "Welcome to a study tool built by students, for students. Create personalized flashcards instantly, study them directly on the platform, and save countless hours while optimizing your learning journey.",
			welcomeMessageTitle: "Welcome to Studyflash",
			uploadDocument: "Upload your document, and let our AI work its magic by generating flashcards for you in seconds. Simply drag and drop your file into the box, or click to browse and select one.",
			uploadDocumentTitle: "Upload a document",
			nameDeck: "Choose a name for your deck that reflects the subject or topic you're studying. This helps you stay organized and quickly find your decks when you need them.",
			nameDeckTitle: "Name your deck",
			generateDeck: "Almost there! You're just one click away from getting a personalized deck created by our AI.",
			generateDeckTitle: "Generate your deck",
			next: "Next",
			getStarted: "Get started now!"
		},
		beta: {
			try: "Want to give it a try?",
			optIn: "Let’s do it!",
			optOut: "Maybe later",
			imageFlashcards: {
				title: "Boost Your Flashcards with Images!",
				description: "We’re testing a new feature that automatically adds images from your files to your flashcards — saving you even more time than before. As a top Studyflash user, you’ve been invited to try it first!"
			}
		},
		constrainedOnboarding: {
			title: "Create New Flashcards",
			subtitle: "Upload your lecture notes and experience the magic!",
			generate: "Generate deck",
			later: "I will create flashcards later"
		},
		deckSharing: {
			sentYouDeck: "Your friend sent you a deck!",
			takeALook: "Take a look at what they shared. Like what you see? Sign up to get access to this deck and make studying simpler and more effective with Studyflash.",
			gotIt: "Got it",
			copyLink: "Copy link",
			noThanks: "No thanks",
			proceed: "Proceed to sign up",
			search: "Search by keyword",
			question: "Question",
			answer: "Answer",
			shareDeck: "Share this deck",
			sendACopy: "Send a copy of this deck to friends who haven’t joined Studyflash yet"
		}
	},
}

export default en
