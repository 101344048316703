<template>
	<div
		id="userInfo"
		class="user-info"
		:style="windowSizeStore.isDesktop ? 'height: 100%;' : ''"
		@click="openSettings"
	>
		<div
			class="container"
			style="align-items: center"
		>
			<img
				v-if="avatarUrl"
				:src="avatarUrl"
				alt="User Avatar"
				class="avatar"
				height="36"
				width="36"
				loading="lazy"
				referrerpolicy="no-referrer"
			>
			<div
				v-else
				class="user-initials"
			>
				{{ userInitials }}
			</div>
			<div
				class="column"
				style="padding-left: 10px"
			>
				<div>
					<p
						class="standard_text"
						style="font-size: 16px; margin-bottom: 0; color: #4a5361; font-weight: 500"
					>
						{{ username }}
					</p>
					<p
						class="standard_text"
						style="font-size: 12px; color: #9a9ea6; margin-bottom: 0; font-weight: 500;"
					>
						{{
							subscriptionStatus
						}}
					</p>
				</div>
			</div>
		</div>
		<img
			src="../../assets/Settings.svg"
			style="width: 24px; height: 24px; margin-left: 24px"
		>
	</div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useUserStore, useWindowSizeStore, useSubscriptionStore } from '@/store/store.js'
import { useTokenBalance } from '@/services/transaction.service';
import { useI18n } from 'vue-i18n'

// Define the emitted events
const emit = defineEmits(['open-settings'])

// Initialize the stores
const userStore = useUserStore()
const windowSizeStore = useWindowSizeStore()
const subscriptionStore = useSubscriptionStore()

// Initialize the i18n instance
const { t } = useI18n()

// Computed property for user initials
const userInitials = computed(() => {
	if (!userStore.user) return 'UN'

	const user = userStore.user

	if (user.first_name && user.last_name) {
		const firstInitial = user.first_name.charAt(0).toUpperCase()
		const lastInitial = user.last_name.charAt(0).toUpperCase()
		return `${firstInitial}${lastInitial}`
	}

	if (user.email) {
		const emailParts = user.email.split('@')[0].split('.')

		if (emailParts.length >= 2) {
			const firstInitial = emailParts[0].charAt(0).toUpperCase()
			const secondInitial = emailParts[1].charAt(0).toUpperCase()
			return `${firstInitial}${secondInitial}`
		} else {
			return user.email.slice(0, 2).toUpperCase()
		}
	}

	return 'UN'
})

// Computed property for username
const username = computed(() => {
	if (userStore.user && userStore.user.name) return userStore.user.name
	if (!userStore.user || !userStore.user.email) return 'Error displaying username'
	return userStore.user.email.split('@')[0]
})

// Computed property for token balance
const {tokenBalance} = useTokenBalance()

// Computed property for avatar URL
const avatarUrl = computed(() => {
	return userStore.user?.avatar_url || null
})

// Computed property for subscription status
const subscriptionStatus = computed(() => {
	const tokens = tokenBalance.value && tokenBalance.value >= 0 ? tokenBalance.value : 0
	const status = subscriptionStore.getSubscriptionStatus()
	switch (status) {
	case 0:
	case 1:
		return t('message.header.basicUser')
	case 2:
	case 5:
		return t('message.header.premiumUser')
	case 3:
	case 4:
		return `${tokens} ${t('message.header.tokensLeft')}`
	default:
		return "Unexpected error"
	}
})

// Method to emit the 'open-settings' event
const openSettings = () => {
	emit('open-settings')
}
</script>

<style scoped>
.user-initials {
	background-color: #EBDAF4;
	border-radius: 100%;
	padding: 0.5rem;
	color: #7a219e;
	font-size: 14px;
	font-weight: 700;
	display: grid;
	place-items: center;
	aspect-ratio: 1;
	width: 35px;
}
</style>
