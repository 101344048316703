<script setup lang="ts">
import { UserService } from '@/services/user.service';
import { useUserStore } from '@/store/store';
import { captureException } from '@sentry/vue';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import { computed, Ref, ref } from 'vue';
import { PencilEdit02Icon } from '@hugeicons/vue';
import LanguagePicker from '@/components/common/LanguagePicker.vue';

const { t } = useI18n()
const userStore = useUserStore()

const editName:Ref<boolean> = ref(false)
const newName:Ref<string> = ref("")

function openNameEdit() {
	editName.value = true
	newName.value = currentName.value
}
async function handleSubmitNewName() {
	if (newName.value === '') return
	const response = await UserService.updateDisplayName(newName.value, userStore.getCurrentUserId())
	if (response.error) {
		console.error(response.error)
		toast.error(t('message.errorCodes.settings.updateDisplayNameFailed'))
		const errorMessage = `Updating display name failed ${response.error.message}`
		captureException(new Error(errorMessage), {
			extra: {
				originalError: response.error
			}
		})
		return
	}

	editName.value = false
}
async function handlePhotoSelected(e:Event) {
	if(!userStore.user) return
	const target = e.target as HTMLInputElement;
	if(target.files && target.files[0]) {
		const newAvatar = target.files[0]
		const response = await userStore.setNewAvatar(newAvatar, userStore.getCurrentUserId(), userStore.user.auth_id)
		if (response.error) {
			console.error(response.error)
			toast.error(t('message.errorCodes.settings.updateProfilePhotoFailed'))
			const errorMessage = `Updating profile photo failed ${response.error.message}`
			captureException(new Error(errorMessage), {
				extra: {
					originalError: response.error
				}
			})
		}
	}
}
function handleSelectPicture() {
	const photoInput = document.getElementById('profile-photo-input')
	if(!photoInput) return 
	photoInput.click()
}

const currentName = computed(() => {
	if (userStore.user === null) return ''
	if (userStore.user.name) return userStore.user.name
	if (userStore.user.email) return userStore.user.email.split('@')[0]
	return ''
})

const avatarUrl = computed(() => {
	if (userStore.user?.avatar_url) {
		return userStore.user.avatar_url;
	}
	return null;
})

const userInitials = computed(() => {
	if (!userStore.user) return 'UN';

	const user = userStore.user;

	if (user.first_name && user.last_name) {
		const firstInitial = user.first_name.charAt(0).toUpperCase();
		const lastInitial = user.last_name.charAt(0).toUpperCase();
		return `${firstInitial}${lastInitial}`;
	}

	if (user.email) {
		const emailParts = user.email.split('@')[0].split('.');

		if (emailParts.length >= 2) {
			const firstInitial = emailParts[0].charAt(0).toUpperCase();
			const secondInitial = emailParts[1].charAt(0).toUpperCase();
			return `${firstInitial}${secondInitial}`;
		}
		else {
			return user.email.slice(0, 2).toUpperCase();
		}
	}

	return 'UN';
})
</script>

<template>
	<div class="flex flex-col gap-6">
		<h1 class="text-2xl text-sf-gray-200 font-bold m-0">
			{{ $t('message.settings.personalInfo') }}
		</h1>
		<div
			class="w-full m-0 flex items-center"
		>
			<div class="profile-picture-container">
				<img
					v-if="avatarUrl"
					:src="avatarUrl"
					alt="User Avatar"
					class="avatar profile-photo"
					style="width: 72px; height: 72px;"
					loading="lazy"
					referrerpolicy="no-referrer"
				>
				<div
					v-else
					class="user-initials"
				>
					{{ userInitials }}
				</div>
				<fa-icon
					icon="fa-solid fa-camera"
					class="invisible-icon"
					@click="handleSelectPicture"
				/>
				<input
					id="profile-photo-input"
					type="file"
					name="profile-photo"
					style="display: none;"
					@change="handlePhotoSelected"
				>
			</div>
			<div class="flex flex-col ml-6 justify-start items-center">
				<div class="m-0">
					<div
						v-if="!editName"
						class="flex gap-2 justify-start items-center"
					>
						<p
							class="text-base m-0 text-sf-gray-200 leading-6" 
						>
							{{ currentName }}
						</p>
						<PencilEdit02Icon
							type="standard"
							variant="stroke"
							color="#4a5361"
							:size="20"
							@click="openNameEdit"
						/>
					</div>
					<div
						v-else
						style="display: flex; margin-bottom: 1rem;"
					>
						<input
							id="newName"
							v-model="newName"
							type="text"
							name="newName"
						>
						<button
							class="delete-account"
							style="margin-left: 1rem;"
							@click="handleSubmitNewName"
						>
							{{ $t('message.settings.submit') }}
						</button>
					</div>
					<p
						class="text-sm text-sf-gray-100 leading-4 m-0"
					>
						{{ userStore.user?.email ? userStore.user.email : "Email missing, please reload" }}
					</p>
				</div>
			</div>
		</div>
		<div>
			<p class="text-base text-sf-gray-200 font-semibold">
				{{ $t('message.settings.language') }}:
			</p>
			<LanguagePicker />
		</div>
	</div>
</template>


<style scoped>

.name-container {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    align-items: left;
    justify-content: flex-start;
}

.locale-icon {
    height: 24px;
    width: 24px;
    border-radius: 50%;
}

.active-icon {
    border: 2px solid #7a219e;
}


.edit-icon {
    cursor: pointer;
}

.profile-picture-container {
    position: relative;
    cursor: pointer;
}

.profile-picture-container:hover .invisible-icon {
    opacity: 1;
    height: 36px;
    width: 36px;
}

.profile-picture-container:hover .profile-photo {
    filter: brightness(0.5);
}

.invisible-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    color: white;
    height: 24px;
    width: 24px;
    transition: height 0.4s ease-in-out;
}

.user-initials {
    background-color: #EBDAF4;
    border-radius: 100%;
    padding: 0.5rem;
    color: #7a219e;
    font-size: 24px;
    font-weight: 700;
    width: 72px;
    height: 72px;
    display: grid;
    place-items: center;
}
</style>
