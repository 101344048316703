import posthog, { PostHog } from 'posthog-js';
import { App } from 'vue';

export default {
	install(app: App) {
		const instance: PostHog = posthog.init(import.meta.env.VITE_POSTHOGKEY, {
			api_host: 'https://studyflash-avffcrddhheqgmd0.z01.azurefd.net',
			ui_host: 'https://eu.posthog.com',
			capture_pageview: false,
			person_profiles: 'identified_only', // Check if this should be always or for identified users only
			// featureFlags: {
			// 	// input the flag values here from 'posthog.getAllFlags(distinct_id)' which you can find in the server-side libraries.
			// 	'test-flag': false
			// },
		})!;

		// Provide PostHog instance globally
		app.provide('posthog', instance);
		app.config.globalProperties.$posthog = instance;

		// Make PostHog accessible globally
		window.posthog = instance; // Use any to bypass strict type checks
	},
};
