import { supabase } from './database'
import { captureException } from '@sentry/vue'

export const replaceSpacesWithUnderscores = (string) => {
	if (typeof string !== 'string') {
		throw new Error('Input must be a string')
	}
	return string.replace(/ /g, '_')
}

export const cleanUpFileName = (string) => {
	const pattern = /^[\w!.*'()&$=:+,?_-]+$/
	const timestamp = new Date().toISOString()
	let sanitizedFilename = Array.from(string)
		.filter((char) => pattern.test(char))
		.join('')
	const lastDotIndex = sanitizedFilename.lastIndexOf('.')

	if (lastDotIndex !== -1) {
		sanitizedFilename = `${sanitizedFilename.slice(
			0,
			lastDotIndex
		)}-${timestamp}${sanitizedFilename.slice(lastDotIndex)}`
	} else {
		sanitizedFilename += `-${timestamp}`
	}

	return sanitizedFilename
}

export const uploadPublicFileAndGetItsLink = async (bucketName, filePath, file) => {
	const { data, error } = await supabase.storage
		.from(bucketName)
		.upload(filePath, file, { upsert: true })
	if (error) {
		if (error.error !== 'Duplicate') {
			console.error(error)
			const errorMessage = `Error occurred while uploading the file: ${error.message || 'Unknown error'
				}`
			const errorObj = new Error(errorMessage)
			captureException(errorObj, {
				extra: {
					originalError: error,
					bucketName,
					filePath,
				},
			})
			return
		}
		const { data: publicUrlData, error: publicErrorData } = supabase.storage
			.from(bucketName)
			.getPublicUrl(filePath)
		if (publicErrorData) {
			console.error(publicErrorData)
			const errorMessage = `Error occurred while fetching the public URL: ${publicErrorData.message || 'Unknown error'
				}`
			const errorObj = new Error(errorMessage)

			captureException(errorObj, {
				extra: {
					originalError: publicErrorData,
					bucketName,
					filePath,
				},
			})
		} else {
			return publicUrlData.publicUrl
		}
	} else {
		const { data: publicUrlData, error: publicErrorData } = supabase.storage
			.from(bucketName)
			.getPublicUrl(data.path)
		if (publicErrorData) {
			console.error(publicErrorData)
			captureException(publicErrorData)
		} else {
			return publicUrlData.publicUrl
		}
	}
}

export const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

export const removeMarkupTagsFromString = (string) => {
	const tagPattern = /<[^>]*>/g
	const strippedString = string.replace(tagPattern, '')
	return strippedString
}

export const decodeToken = (token) => {
	const base64Url = token.split('.')[1]
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
	const jsonPayload = decodeURIComponent(
		atob(base64)
			.split('')
			.map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
			.join('')
	)

	const parsedToken = JSON.parse(jsonPayload)
	return parsedToken
}


export const getTourHeaderString = (currentStep, maxStep) => {
	let string = '<div style="display: flex; justify-content: space-between; width: 100%; gap: 1rem; align-items: center;">'
	for (let i = 0; i < currentStep; i++) {
		string += '<div style="background-color: #7a219e; flex:1; height: 4px; border-radius: 8px;"></div>'
	}
	for (let j = 0; j < (maxStep - currentStep); j++) {
		string += '<div style="background-color: #F7E6FF; flex:1; height: 4px; border-radius: 8px;"></div>'
	}
	string += '</div>'
	return string
}

//When using wrap in try catch!
export const decodeEdgeFunctionErrorResponse = async (errorObject) => {
	const errorTextStream = await errorObject.context.body.getReader().read()
	const decoder = new TextDecoder("utf-8");
	const errorMessage = decoder.decode(errorTextStream.value);
	return JSON.parse(errorMessage)
}

export const isMoreThanXDaysAgo = (date, x) => {
	const currentDate = new Date();
	const inputDate = new Date(date);

	const timeDifference = currentDate - inputDate;
	const dayDifference = timeDifference / (1000 * 3600 * 24);

	return dayDifference > x;
}
