import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import SignUp from './views/SignUp.vue'
import { supabase } from './common/database'
import { toast } from 'vue3-toastify'
import { decodeToken } from './common/util.js'
import { captureException, setUser as setSentryUser } from '@sentry/vue'
import { useUserStore, useWindowSizeStore } from './store/store'
import { useI18n } from 'vue-i18n'
const SignUpPassword = () => import('@/views/SignUpPassword.vue')
const Study = () => import('@/views/Study.vue')
const ConstrainedOnboarding = () => import('@/views/ConstrainedOnboarding.vue')
const DeckPreviewView = () => import('@/views/DeckPreviewView.vue')
const MaintenanceComponent = () => import('@/components/common/Maintenance.vue')
const DashboardView = () => import('@/views/DashboardView.vue')

const maintenanceMode = import.meta.env.VITE_MAINTENANCE_MODE === 'true'
const routes: RouteRecordRaw[] = [
	{
		path: '/',
		redirect: '/dashboard',
	},
	{
		path: '/dashboard',
		name: 'dashboard',
		component: DashboardView,
		meta: { requiresAuth: true },
	},
	{
		path: '/decks',
		name: 'decks',
		component: () => import('./views/DecksView.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/signup',
		name: 'signup',
		component: SignUp,
		meta: { hideForAuth: true },
	},
	// Conditionally include the signup with password route
	...(import.meta.env.VITE_MODE !== 'production'
		? [
				{
					path: '/signup_email',
					name: 'signup_email',
					component: SignUpPassword,
					meta: { hideForAuth: true },
				},
		  ]
		: []),
	{
		path: '/signin',
		redirect: () => {
			return { path: '/signup', query: { mode: 'signin' } }
		},
		meta: { hideForAuth: true },
	},
	{
		path: '/onboarding',
		name: 'onboarding',
		component: () => import('./views/OnboardingView.vue'),
		meta: { requiresAuth: true },
	},

	{
		path: '/decks/:id',
		name: 'deck',
		component: () => import('./views/DeckOverviewPage.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/create',
		name: 'create',
		component: () => import('./views/CreateDeck.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/study/:id?',
		name: 'Study',
		component: Study,
		meta: { requiresAuth: true },
	},
	{
		path: '/opt-in/uuid/:uuid',
		name: 'opt-in',
		component: () => import('./views/newsLetterOptIn.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/study-session/:id',
		name: 'study-session',
		component: () => import('./views/CustomStudySession.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/deck-bin',
		name: 'deck-bin',
		component: () => import('./views/DeckBin.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/shop',
		name: 'subscription-shop',
		component: () => import('./views/SubscriptionShop.vue'),
		meta: { requiresAuth: true },
	},
	{
		path: '/logout',
		name: 'logout',
		beforeEnter: async () => {
			await logOut()
			console.log('logged out')
		},
		redirect: '/signup',
		meta: { requiresAuth: false },
	},
	{
		path: '/maintenance',
		name: 'Maintenance',
		component: MaintenanceComponent,
	},
	{
		path: '/welcome',
		name: 'Welcome',
		component: ConstrainedOnboarding,
		meta: { requiresAuth: true },
	},
	{
		path: '/preview',
		name: 'Preview',
		component: DeckPreviewView,
		meta: { hideForAuth: true },
	},
	{
		path: '/:catchAll(.*)',
		redirect: '/dashboard',
	},
]

async function logOut(shouldPush = true) {
	const { error } = await supabase.auth.signOut()
	if (error) {
		console.error(error)
		const errorMessage = `Failed to log out user. Supabase sign-out error: ${
			error.message || 'Unknown error'
		}`
		const errorObj = new Error(errorMessage)
		captureException(errorObj, {
			extra: {
				originalError: error,
			},
		})
	} else {
		setSentryUser(null)
		window.posthog.reset()
		if (shouldPush) router.push({ name: 'signup' })
	}
	const windowSizeStore = useWindowSizeStore()
	windowSizeStore.debugLogout = true
}

export const router = createRouter({
	history: createWebHistory(),
	routes,
})

router.onError((err) => {
	console.error(err)
	const errorMessage = `Routing error: ${err.message || 'Unknown error'}`
	const errorObj = new Error(errorMessage)
	captureException(errorObj, {
		extra: {
			originalError: err,
			route: router.currentRoute.value.fullPath,
		},
	})

	toast('Routing error')
})

router.beforeEach(async (to, from, next) => {
	async function checkIsLoggedIn() {
		const authResponse = await supabase.auth.getUser()
		return authResponse.error === null
	}

	if (maintenanceMode && to.name !== 'Maintenance') {
		return next({ name: 'Maintenance' })
	} else if (!maintenanceMode && to.name === 'Maintenance') {
		return next({ name: 'dashboard' })
	}

	const userStore = useUserStore()
	const user = userStore.user
	const requiresAuth = to.matched.some((record) => record.meta.requiresAuth === true)
	const hiddenForAuth = to.matched.some((record) => record.meta.hideForAuth === true)
	const isLoggedIn = await checkIsLoggedIn()

	if (requiresAuth) {
		if (!isLoggedIn) {
			return next({ name: 'signup' })
		}
		const profilePromise = user ? Promise.resolve(user) : fetchUserProfile()
		const profile = await profilePromise
		if (!profile) {
			const { t } = useI18n()
			// this is a fallback and should absolutely never happen
			toast.error(t('message.errorCodes.router.accountAccessError'))
			await logOut(false)
			return next({ name: 'signup' })
		}
		if (profile.has_onboarded) {
			if (to.name === 'onboarding') {
				return next({ name: 'dashboard' })
			}
			return next()
		}
		if (to.name !== 'onboarding') {
			return next({ name: 'onboarding', query: to.query })
		}
		return next()
	} else if (hiddenForAuth) {
		if (isLoggedIn) {
			return next({ name: 'dashboard' })
		}
	}
	next()
})

async function fetchUserProfile() {
	const session = await supabase.auth.getSession()
	if (session.error) return null
	const access_token = session.data?.session?.access_token
	if (!access_token) return null
	const decoded = decodeToken(access_token)
	if (!decoded) return null
	const userId = decoded.profile_id

	const { data, error } = await supabase.from('profiles').select('*').eq('id', userId).single()

	if (error) {
		console.error('Error fetching profile:', error)
		const errorMessage = `Error fetching user profile ,Supabase error: ${
			error.message || 'Unknown error'
		}`
		const errorObj = new Error(errorMessage)
		captureException(errorObj, {
			extra: {
				originalError: error,
				userId: userId,
			},
		})
		return null
	}

	return data
}
