import { Flashcard } from "@/types/Flashcard";
import { PostgrestSingleResponse, RealtimeChannel, RealtimePostgresInsertPayload, RealtimePostgresUpdatePayload, SupabaseClient } from "@supabase/supabase-js";
import { v4 as uuidv4 } from 'uuid'
import { toast } from "vue3-toastify";

type Range = [number, number];
export interface DeckProps {
    id: string,
    name: string,
    _deleted: boolean,
    _modified: string,
    parent_deck_id: string,
    created_at: string,
    created_by: string,
    number_of_chunks: number,
    chunks_completed: number,
    chunks_failed: number,
    deck_rating: number,
    deck_rating_feedback: string,
    failed_pages: Range[],
    is_manual: boolean,
    number_of_image_chunks: number;
    image_chunk_processed: number;
    is_shared: boolean;
}

export interface DeckCreationProps {
    name: string,
    parent_deck_id: string | null,
    created_by: string,
    is_manual: boolean
}
export interface DeckCreationDto extends DeckCreationProps {
    number_of_chunks: number,
    chunks_completed: number;
    chunks_failed: number,
    created_at: string,
    id: string
}

export interface DeckUpdateDTO {
    name: string,
    _deleted: boolean,
    parent_deck_id: string,
    deck_rating: number,
    deck_rating_feedback: string,
}

export class Deck {
    id: string;
    name: string;
    deleted: boolean;
    modified: string;
    parent_deck_id: string;
    created_at: string;
    created_by: string;
    number_of_chunks: number;
    chunks_completed: number;
    chunks_failed: number;
    deck_rating: number;
    deck_rating_feedback: string;
    failed_pages: Range[];
    is_manual: boolean;
    number_of_image_chunks: number;
    image_chunk_processed: number;
    is_shared: boolean;

    cards_total: number = 0;
    cards_review: number = 0;
    cards_learning: number = 0;
    cards_new: number = 0;

    supabaseClient: SupabaseClient;
    flashcards: Flashcard[] = [];
    flashcardSubscription: RealtimeChannel | null = null

    constructor(deckData: DeckProps, supabaseClient: SupabaseClient) {
        this.id = deckData.id
        this.name = deckData.name
        this.deleted = deckData._deleted
        this.modified = deckData._modified
        this.parent_deck_id = deckData.parent_deck_id
        this.created_at = deckData.created_at
        this.created_by = deckData.created_by
        this.number_of_chunks = deckData.number_of_chunks
        this.chunks_completed = deckData.chunks_completed
        this.chunks_failed = deckData.chunks_failed
        this.deck_rating = deckData.deck_rating
        this.deck_rating_feedback = deckData.id
        this.failed_pages = deckData.failed_pages
        this.is_manual = deckData.is_manual
        this.number_of_image_chunks = deckData.number_of_image_chunks
        this.image_chunk_processed = deckData.image_chunk_processed
        this.supabaseClient = supabaseClient
        this.is_shared = deckData.is_shared
    }

    setFields(updateData: Partial<DeckProps>) {
        Object.keys(updateData).forEach((key) => {
            if (key in this) {
                // @ts-expect-error - We know this is safe due to the check above
                this[key] = updateData[key as keyof DeckProps];
            }
        });
    }
    async fetchFlashcards(): Promise<void> {
        const response: PostgrestSingleResponse<Flashcard[]> =
            await this.supabaseClient.from('flashcards').select("*")
                .eq('deck_id', this.id).eq('_deleted', false)
                .order('chunk_order_id', { ascending: true, nullsFirst: true })
                .order('chunk_order_id', { ascending: true })
                .order('order_id', { ascending: true })
                .order('position', { ascending: true })
        if (response.error) {
            console.error(response.error)
            return
        }
        this.flashcards = []
        this.flashcards.push(...response.data)
    }
    subscribeToFlashcardInserts() {
        this.flashcardSubscription = this.supabaseClient
            .channel('flashcard_inserts')
            .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'flashcards', filter: `deck_id=eq.${this.id}` }, (payload: RealtimePostgresInsertPayload<Flashcard>) => {
                this.flashcards.push(payload.new)
            })
            .subscribe()
    }
    unsubscribeFromFlashcardInserts() {
        if (!this.flashcardSubscription) return
        this.supabaseClient.removeChannel(this.flashcardSubscription)
    }
    async rateDeck(rating: number, description: string) {
        return await this.supabaseClient
            .from('decks')
            .update({ deck_rating: rating, deck_rating_feedback: description })
            .eq('id', this.id)
    }
    parseAndSortRanges(): string {
        const validRanges: Range[] = this.failed_pages.filter(
            (range): range is Range =>
                Array.isArray(range) &&
                range.length === 2 &&
                Number.isInteger(range[0]) &&
                Number.isInteger(range[1])
        );

        validRanges.sort((a, b) => a[0] - b[0]);

        const result: string[] = [];
        let currentRange: Range | null = null;

        for (const range of validRanges) {
            if (!currentRange) {
                currentRange = range;
            } else if (range[0] <= currentRange[1] + 1) {
                currentRange[1] = Math.max(currentRange[1], range[1]);
            } else {
                result.push(this.formatRange(currentRange));
                currentRange = range;
            }
        }

        if (currentRange) {
            result.push(this.formatRange(currentRange));
        }

        return result.join(', ');
    }
    private formatRange(range: Range): string {
        return range[0] === range[1] ? `${range[0]}` : `${range[0]}-${range[1]}`;
    }
    async updateParentDeckId(newId: string | null) {
        return await this.supabaseClient.from('decks').update({ parent_deck_id: newId }).eq('id', this.id)
    }
    async updateDeck(dto: DeckUpdateDTO) {
        return await this.supabaseClient.from('decks').update(dto).eq('id', this.id)
    }
    async deleteFlashcard(flashcardId: string) {
        const cardToDelete = this.flashcards.find(card => card.id === flashcardId)
        if (!cardToDelete) {
            return { data: null, error: { message: `Attempted to delete card ${flashcardId} that is not in deck!` } }
        }
        const deletionResponse = await this.supabaseClient.from('flashcards').update({ '_deleted': true }).eq('id', cardToDelete.id)
        cardToDelete._deleted = true
        return deletionResponse
    }
    reinsertFlashcard(flashcard: Flashcard) {
        const cardToReinsert = this.flashcards.find(card => card.id === flashcard.id)
        if (!cardToReinsert) {
            toast('PLEASE HANDLE')
            return
        }
        cardToReinsert._deleted = false
    }
    static async fetchAll(supabase: SupabaseClient): Promise<PostgrestSingleResponse<DeckProps[]>> {
        const response: PostgrestSingleResponse<DeckProps[]> = await supabase.from('decks').select('*').eq('_deleted', false)
        return response
    }
    static async createNewDeck(supabase: SupabaseClient, deckProps: DeckCreationProps): Promise<PostgrestSingleResponse<DeckProps>> {
        const dto: DeckCreationDto = {
            ...deckProps,
            number_of_chunks: 0,
            chunks_completed: 0,
            chunks_failed: 0,
            created_at: new Date().toISOString(),
            id: uuidv4(),
        }
        return await supabase.from('decks').insert(dto).select().single()
    }
    static subscribeToUpdate(supabase: SupabaseClient, callback: (payload: RealtimePostgresUpdatePayload<DeckProps>) => object): RealtimeChannel {
        return supabase
            .channel('decks_updates')
            .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'decks' }, (payload) =>
                callback(payload as RealtimePostgresUpdatePayload<DeckProps>)
            )
            .subscribe()
    }
    static subscribeToInsert(supabase: SupabaseClient, callback: (payload: RealtimePostgresInsertPayload<DeckProps>) => object): RealtimeChannel {
        return supabase
            .channel('decks_inserts')
            .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'decks' }, (payload) =>
                callback(payload as RealtimePostgresInsertPayload<DeckProps>)
            )
            .subscribe()
    }
    static async bulkDeleteDecks(supabase: SupabaseClient, deckIdArray: string[]) {
        return await supabase.from('decks').update({ _deleted: true }).in('id', deckIdArray)
    }
    static async fetchSpecificDeck(supabase: SupabaseClient, deckId: string): Promise<PostgrestSingleResponse<DeckProps>> {
        return await supabase.from('decks').select("*").eq("id", deckId).single()
    }
}