import { createApp, nextTick } from 'vue'
import 'vue3-toastify/dist/index.css'
import 'shepherd.js/dist/css/shepherd.css'
import './style.css'
import App from './App.vue'
import { router } from './router'
import { createPinia } from 'pinia'
import { VueQueryPlugin } from '@tanstack/vue-query'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
	faUserSecret,
	faA,
	faBold,
	faItalic,
	faStrikethrough,
	faCode,
	faListOl,
	faListUl,
	faQuoteLeft,
	faSubscript,
	faSuperscript,
	faUnderline,
	faRotateLeft,
	faRotateRight,
	faTextSlash,
	faMinus,
	faArrowTurnDown,
	faT,
	faX,
	faPencil,
	faTrash,
	faRectangleList,
	faPlus,
	faGripVertical,
	faAngleLeft,
	faHighlighter,
	faImage,
	faAngleRight,
	faGraduationCap,
	faFolder,
	faBook,
	faTrashCanArrowUp,
	faCheck,
	faQuestion,
	faRocket,
	faMagnifyingGlass,
	faBars,
	faCamera,
	faUserShield,
	faPaperPlane,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { createI18n } from 'vue-i18n'
import en from './i18n/en'
import de from './i18n/de'
import Vue3Toastify from 'vue3-toastify'
import { h } from 'vue'
import logo from './assets/s.svg'
import { supabaseIntegration } from '@supabase/sentry-js-integration'
import posthogPlugin from './plugins/posthog'
import posthog from 'posthog-js'
import { SupabaseClient } from '@supabase/supabase-js'

import {
	init as sentryInit,
	captureException,
	captureMessage,
	browserTracingIntegration,
	browserProfilingIntegration,
	httpClientIntegration,
	startInactiveSpan,
	addBreadcrumb,
} from '@sentry/vue'

const Sentry = {
	init: sentryInit,
	captureException,
	captureMessage,
	browserTracingIntegration,
	browserProfilingIntegration,
	httpClientIntegration,
	startInactiveSpan,
	addBreadcrumb,
}

const faIcons = [
	faUserSecret,
	faA,
	faBold,
	faItalic,
	faStrikethrough,
	faCode,
	faListOl,
	faListUl,
	faQuoteLeft,
	faSubscript,
	faSuperscript,
	faUnderline,
	faRotateLeft,
	faRotateRight,
	faTextSlash,
	faMinus,
	faArrowTurnDown,
	faT,
	faPencil,
	faX,
	faTrash,
	faRectangleList,
	faPlus,
	faGripVertical,
	faAngleLeft,
	faHighlighter,
	faImage,
	faAngleRight,
	faGraduationCap,
	faFolder,
	faBook,
	faTrashCanArrowUp,
	faRocket,
	faCheck,
	faQuestion,
	faMagnifyingGlass,
	faBars,
	faCamera,
	faUserShield,
	faPaperPlane,
]
library.add(...faIcons)

const pinia = createPinia()
const app = createApp(App).component('font-awesome-icon', FontAwesomeIcon)

Sentry.init({
	app,
	dsn: 'https://6ab213d51e8caaa56bc12398698bba90@o4507571660521472.ingest.de.sentry.io/4507622656573520',
	integrations: [
		Sentry.browserTracingIntegration({
			router,
			routeLabel: 'path',
			shouldCreateSpanForRequest: (url) => {
				return !url.startsWith(`${import.meta.env.VITE_SUPABASE_URL}/rest`)
			},
		}),
		Sentry.httpClientIntegration({
			failedRequestStatusCodes: [[400, 599]],
		}),
		Sentry.browserProfilingIntegration(),
		supabaseIntegration(SupabaseClient, Sentry, {
			tracing: true,
			breadcrumbs: true,
			errors: true,
		}),
		posthog.sentryIntegration({
			organization: 'studyflash',
			projectId: 4507622656573520,
			severityAllowList: ['error'],
		}),
	],
	// This option is required for capturing headers and cookies.
	sendDefaultPii: true,
	tracesSampleRate: 1.0,
	profilesSampleRate: 1.0,
	environment: import.meta.env.VITE_MODE,
	beforeSend: (event, hint) => {
		if (typeof hint.originalException === 'object' && hint.originalException != null) {
			event.extra = {
				...event.extra,
				...hint.originalException,
			}
		}

		return event
	},
})

window.addEventListener('vite:preloadError', (event) => {
	console.log('preload error', event)
	Sentry.captureMessage('Captured preload error')
	window.location.reload() // Refresh the page on preload error
})

const imgVNode = h('img', {
	src: logo,
	alt: 'Studyflash notification - logo',
	width: 30,
	height: 50,
})

app.component('FaIcon', FontAwesomeIcon)
app.use(posthogPlugin)
app.use(pinia)
app.use(router)
app.use(VueQueryPlugin)

router.afterEach((to, from, failure) => {
	if (!failure) {
		nextTick(() => {
			app.config.globalProperties.$posthog.capture('$pageview', { path: to.fullPath })
		})
	}
})

app.use(Vue3Toastify, {
	autoClose: 3000,
	pauseOnHover: true,
	icon: imgVNode,
})
const i18n = createI18n({
	locale: 'en',
	legacy: false,
	messages: {
		en: en,
		de: de,
	},
})
app.use(i18n)
app.mount('#app')
