<script>
import { useWindowSizeStore } from '../../store/store.js'

export default {
	name: "LoadingState",
	setup() {
		const windowSizeStore = useWindowSizeStore()

		return {
			windowSizeStore
		}
	},
	data() {
		return {
			dotNumber: 0,
			interval: null
		}
	},
	mounted() {
		this.interval = setInterval(() => {
			this.updateDotNumber()
		}, 500)
	},
	unmounted() {
		clearInterval(this.interval)
	},
	methods: {
		updateDotNumber() {
			this.dotNumber = (this.dotNumber % 3) + 1;
		}
	}
}

</script>


<template>
	<div class="loader-background">
		<div>
			<img
				src="../../assets/logo-white.svg"
				alt="SF-logo"
				class="sf-logo"
				:width="windowSizeStore.isDesktop ? 500 : 300"
				:height="windowSizeStore.isDesktop ? 120 : 71"
			>
			<br>
			<p :class="windowSizeStore.isDesktop ? 'animated-text' : 'animated-text-mobile'" />
		</div>
	</div>
</template>


<style scoped>
.container-div {
    width: max-content;
    margin: 0 auto;
}

.loader-background {
    width: 100%;
    height: 100vh;
    background-color: #7a219e;
    display: grid;
    place-items: center;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.sf-logo {
    animation: fadeIn 1s ease-out;
}

@keyframes fadeInSlideDown {
    0% {
        opacity: 0;
        transform: translateY(-15px);
    }

    25% {
        opacity: 1;
        transform: translateY(-15px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.animated-text {
    animation: fadeInSlideDown 1s ease-in;
    font-size: 2rem;
    color: white;
    margin-left: 4rem;
    margin-top: -5px;
}

.animated-text-mobile {
    animation: fadeInSlideDown 1s ease-in;
    font-size: 1.5rem;
    color: white;
    margin-top: -5px;
}

.dot {
    animation: fadeIn 0.3s ease-in;
    font-size: 2rem;
    color: white;
}

.dot-invisible {
    opacity: 0;
    animation: fadeIn 0.3s ease-in;
    font-size: 2rem;
    color: white;
}
</style>