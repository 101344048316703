<script setup lang="ts">
import { computed, PropType } from 'vue';

/*
USAGE EXAMPLE
<GenericButton 
        type="primary" text="Button text"
        :onClick="handleAction" :instanceSpecificStyle="{backgroundColor : 'red'}"
        :icon="{ component: PlayIcon, props: { type: 'rounded', variant: 'stroke', size: '24' } }" />
*/

const props = defineProps({
	type: {
		type: String,
		required: true
	},
	onClick: {
		type: Function as PropType<(event: MouseEvent) => void>,
		default: () => {}
	},
	style: {
		type: Object,
		required: false,
		default: () => {}
	},
	text: {
		type: String,
		required: false,
		default: ""
	},
	// Huge Icon component and its props
	icon: {
		type: Object || null,
		required: false,
		default: null
	},
	disabled: {
		type: Boolean,
		default: false
	},
	id: {
		type: String || null,
		required: false,
		default: null
	}
})

const classList = computed(() => {
	switch (props.type) {
	case 'primary':
		return 'bg-[#7a219e] text-white hover:bg-[#944cb0]';
	case 'secondary':
		return 'bg-white text-[#7a219e] hover:bg-[#F6F1F8]';
	case 'tertiary':
		return 'bg-[#f7e6ff] text-[#7a219e] hover:bg-[#7a219e] hover:text-white';
	case 'delete':
		return 'bg-red-500 text-white hover:bg-red-300';
	default:
		return '';
	}
});
</script>

<template>
	<button
		:id="props.id && props.id"
		:class="['flex flex-row gap-x-4 px-6 py-3 rounded-lg font-semibold cursor-pointer text-base border-std h-fit items-center justify-center text-center disabled:cursor-not-allowed disabled:opacity-50', classList]"
		:style="props.style"
		style="transition: background-color 200ms ease-in-out, opacity 200ms ease-in-out;"
		:disabled="props.disabled"
		@click="props.onClick"
	>
		<component
			:is="icon.component"
			v-if="icon?.component"
			v-bind="icon.props"
		/>
		<span v-if="text">{{ text }}</span>
	</button>
</template>

<style scoped>
</style>
