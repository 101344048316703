export interface UserProps {
    // Technical info and fkey
    id: string;
    auth_id?: string | null;
    stripe_customer_id?: string | null;
    _created?: Date;
    _modified: Date;
    _deleted: boolean;

    // Invite props
    invite_uid: string;
    applied_referral_code?: string | null;

    // Flags that dictate app flow
    subscription_user?: boolean;
    has_onboarded?: boolean;
    used_test_tokens?: boolean;
    seen_spaced_repetition_popup?: boolean;
    show_nps_popup: boolean;
    show_onboarding_tour?: boolean;
    show_token_popup?: boolean;
    seen_welcome_message?: boolean;

    // Kinda useless flags
    agreed_on_terms_of_service?: boolean;
    newsletter_opt_in?: boolean;
    news_letter_opt_in_uuid?: string;

    // Locale info
    locale: string;
    currency?: string;

    // PII
    email?: string | null;
    avatar_url?: string | null;
    name?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    birthday?: string | null;
    country?: string | null;
    institute?: string | null;
    degree_level?: string | null;
    subject_of_studies?: string | null;
    study_start_date?: string | null;
    how_come?: string | null;
}
export class User {
    // Technical info and foreign key
    id: string;
    auth_id: string | null;
    stripe_customer_id: string | null;
    _created: Date;
    _modified: Date;
    _deleted: boolean;

    // Invite props
    invite_uid: string;
    applied_referral_code: string | null;

    // Flags that dictate app flow
    subscription_user: boolean;
    has_onboarded: boolean;
    used_test_tokens: boolean;
    seen_spaced_repetition_popup: boolean;
    show_nps_popup: boolean;
    show_onboarding_tour: boolean;
    show_token_popup: boolean;
    seen_welcome_message: boolean;

    // Kinda useless flags
    agreed_on_terms_of_service: boolean;
    newsletter_opt_in: boolean;
    news_letter_opt_in_uuid: string;

    // Locale info
    locale: string;
    currency: string;

    // PII
    email: string | null;
    avatar_url: string | null;
    name: string | null;
    first_name: string | null;
    last_name: string | null;
    birthday: string | null;
    country: string | null;
    institute: string | null;
    degree_level: string | null;
    subject_of_studies: string | null;
    study_start_date: string | null;
    how_come: string | null;

    constructor(props: UserProps) {
        // Technical info and foreign key
        this.id = props.id;
        this.auth_id = props.auth_id ?? null;
        this.stripe_customer_id = props.stripe_customer_id ?? null;
        this._created = props._created ?? new Date();
        this._modified = props._modified ?? new Date();
        this._deleted = props._deleted ?? false;

        // Invite props
        this.invite_uid = props.invite_uid;
        this.applied_referral_code = props.applied_referral_code ?? null;

        // Flags that dictate app flow
        this.subscription_user = props.subscription_user ?? true;
        this.has_onboarded = props.has_onboarded ?? false;
        this.used_test_tokens = props.used_test_tokens ?? false;
        this.seen_spaced_repetition_popup = props.seen_spaced_repetition_popup ?? false;
        this.show_nps_popup = props.show_nps_popup ?? false;
        this.show_onboarding_tour = props.show_onboarding_tour ?? true;
        this.show_token_popup = props.show_token_popup ?? true;
        this.seen_welcome_message = props.seen_welcome_message ?? false;

        // Kinda useless flags
        this.agreed_on_terms_of_service = props.agreed_on_terms_of_service ?? false;
        this.newsletter_opt_in = props.newsletter_opt_in ?? false;
        this.news_letter_opt_in_uuid = props.news_letter_opt_in_uuid ?? '';

        // Locale info
        this.locale = props.locale ?? 'en';
        this.currency = props.currency ?? 'CHF';

        // PII
        this.email = props.email ?? null;
        this.avatar_url = props.avatar_url ?? null;
        this.name = props.name ?? null;
        this.first_name = props.first_name ?? null;
        this.last_name = props.last_name ?? null;
        this.birthday = props.birthday ?? null;
        this.country = props.country ?? null;
        this.institute = props.institute ?? null;
        this.degree_level = props.degree_level ?? null;
        this.subject_of_studies = props.subject_of_studies ?? null;
        this.study_start_date = props.study_start_date ?? null;
        this.how_come = props.how_come ?? null;
    }

    // TODO: add static methods and replace user.service.js
    // TODO: add instance methods to make class functional
}

