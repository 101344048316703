<script setup lang="ts">
import { ref, onErrorCaptured, computed} from 'vue'
import { useUserStore, useWindowSizeStore } from '../../store/store'

import {
	captureException,
	getCurrentScope
} from '@sentry/vue'

const Sentry = {
	getCurrentScope,
	captureException
}

const windowSizeStore = useWindowSizeStore()
const userStore = useUserStore()

const hasError = ref(false)
const errorMessage = ref("")
const fullError = ref("")


// Error handler for capturing errors
onErrorCaptured((error, instance , info) => {
	console.error(error)
	reportToSentry(error, info)
	hasError.value = true
	errorMessage.value = (error as Error).message || "An error occurred"
	fullError.value = JSON.stringify(error)
	return false
})

function reportToSentry(error: Error, info : string) {
	console.error(error);
	Sentry.getCurrentScope().setLevel("fatal");
	Sentry.captureException(error, {
		extra: {
			info: info,
			userId: userStore.user?.id,
			userEmail: userStore.user?.email
		}
	})
}

async function reload() {
	window.location.reload()
}

const mailtoLink = computed(() => {
	const emailAddress = 'team@studyflash.ch';
	const subject = 'Unexpected error feedback!';
	const body = `
Hi Studyflash team,
Something went wrong and I need some help!

<<Please explain the issue you are facing here>>

Best regards,
<<Your name>>

Error: 
${errorMessage.value}
  `;

	return (
		'mailto:' +
    encodeURIComponent(emailAddress) +
    '?subject=' +
    encodeURIComponent(subject) +
    '&body=' +
    encodeURIComponent(body)
	);
});
</script>

<template>
	<div
		v-if="hasError"
		:class="windowSizeStore.isDesktop ? 'error-wrapper' : 'error-wrapper-mobile'"
	>
		<h2 :class="windowSizeStore.isDesktop ? 'desktop-h2' : ''">
			{{ $t('message.errorBoundary.title') }}
		</h2>
		<p :class="windowSizeStore.isDesktop ? 'desktop-p' : ''">
			{{ $t('message.errorBoundary.p11') }}<span
				class="url-like"
				@click="reload"
			>{{ $t('message.errorBoundary.p12') }}</span>{{
				$t('message.errorBoundary.p13') }}<a
				href="/logout"
				class="url-like"
			>{{ $t('message.errorBoundary.p14')
			}}</a>
		</p>
		<p :class="windowSizeStore.isDesktop ? 'desktop-p' : ''">
			{{ $t('message.errorBoundary.p21') }}
			<a
				:href="mailtoLink"
				class="url-like"
			>
				{{ $t('message.errorBoundary.p22') }}
			</a>
		</p>
	</div>
	<slot v-else />
</template>

<style scoped>
.desktop-h2 {
    font-size: 22px;
}

.desktop-p {
    font-size: 16px;
}

.error-wrapper {
    border: 1px solid #f1e8f4;
    border-radius: 8px;
    padding: 16px 36px;
    background-color: white;
    width: max-content;
    max-width: 100%;
    margin: 5rem auto;
}

.error-wrapper-mobile {
    border: 1px solid #f1e8f4;
    border-radius: 8px;
    padding: 8px 12px;
    background-color: white;
    width: max-content;
    max-width: 95%;
    margin: 5rem auto;

}

.url-like {
    text-decoration: none;
    color: #7a219e;
    cursor: pointer;
    font-weight: 700;
}
</style>