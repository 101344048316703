export interface SubscriptionProps {
    id: string;
    user_id: string;
    stripe_subscription_id?: string | null;
    type?: string | null;
    start_date: string;
    end_date: string;
    active: boolean;

}

export class Subscription {
    id: string;
    user_id: string;
    stripe_subscription_id?: string | null;
    type?: string | null;
    start_date: string;
    end_date: string;
    active: boolean | null;

    constructor(data: SubscriptionProps) {
        this.id = data.id;
        this.user_id = data.user_id;
        this.stripe_subscription_id = data.stripe_subscription_id ?? null;
        this.type = data.type ?? null;
        this.start_date = data.start_date;
        this.end_date = data.end_date;
        this.active = data.active;
    }
}
