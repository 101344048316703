<script setup lang="ts">
import { onMounted, ref, onBeforeMount, watch, onUnmounted, inject, defineAsyncComponent} from 'vue'
import { supabase } from './common/database'



import MainHeader from './components/layout/MainHeader.vue'
import SettingsPanel from './components/layout/SettingsPanel.vue'

const ReferralPopup = defineAsyncComponent(() => import('@/components/layout/ReferralPopup.vue'));
const MobileNavbar = defineAsyncComponent(() => import('./components/layout/MobileNavbar.vue'));
const LeftNavbar = defineAsyncComponent(() => import('./components/layout/LeftNavbar.vue'));

import FeedbackPopup from './components/layout/FeedbackPopup.vue'
import 'typeface-manrope'
import {
	useWindowSizeStore,
	useUserStore,
	useDeckStore,
	initStores,
} from './store/store'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import ErrorBoundary from './components/common/ErrorBoundary.vue'
import { toast } from 'vue3-toastify'
import GenericPopup from './components/common/GenericPopup.vue'
import PromoterScorePopup from './components/common/PromoterScorePopup.vue'
import RatingScale from './components/common/RatingScale.vue'
import { captureException, setUser as setSentryUser } from "@sentry/vue";
import LoadingState from './components/common/LoadingState.vue'
import { PostHog } from 'posthog-js'
import { decodeEdgeFunctionErrorResponse } from './common/util'

const maintenanceMode = import.meta.env.VITE_MAINTENANCE_MODE === 'true'
const { t } = useI18n()
const { locale } = useI18n({ useScope: 'global' })
const router = useRouter()
const route = useRoute()
const posthog:PostHog = inject('posthog')!;

const windowSizeStore = useWindowSizeStore()
const deckStore = useDeckStore()
const userStore = useUserStore()

const showNavbarAndHeader = ref(true)
const deckRating = ref(10)
const deckFeedback = ref('')

const notShowingNavbarAndHeaderPaths = [
	'/signin',
	'/signup',
	'/signup_email',
	'/onboarding',
	'/reset-password',
	'/request-password-reset',
	'/study',
	'/maintenance',
	'/welcome',
	'/preview'
]

function handleUnhandledPromises(e:PromiseRejectionEvent) {
	e.preventDefault()
	const errorMessage = `Unhandled Promise Rejection: ${e.reason?.message || 'Unknown error occurred'}`;
	console.error(errorMessage)
	const sourceURL = e.reason.sourceURL

	// Check if sourceURL contains 'posthog-js.js' and continue if it does
	if (sourceURL && sourceURL.includes('posthog-js.js')) {
		return;
	}

	captureException(e.reason);
	if (e.reason && e.reason.code !== 25) {
		toast.error(t('message.errorCodes.general.unhandledPromise'))
	}
}

onMounted(async () => {
	window.addEventListener('unhandledrejection', handleUnhandledPromises);
	window.addEventListener('resize', windowSizeStore.updateIsDesktop);

	supabase.auth.onAuthStateChange(async (event, session) => {
		if (event === 'SIGNED_OUT') {
			posthog.reset();
			setSentryUser(null);
		} else if (event === 'SIGNED_IN' && (!userStore.user || userStore.user?.email !== session?.user?.email)) {
			posthog.capture('general:sign_in');
		}
	});

	const authResponse = await supabase.auth.getUser();
	if (authResponse.error) return;

	await initStores();
	setLocale();

	if (!['/signup', '/signin', '/preview'].includes(route.path) && route.query.share) {
		const query = route.query;
		const shareId = route.query.share
		delete query.share
    
		router.replace({ query: query });

		if (userStore.user?.has_onboarded) return

		const {error} = await supabase.functions.invoke('copy-deck', {
			body: JSON.stringify({
				id: shareId,
			}),
		})
		if(error) {
			const errorObject = await decodeEdgeFunctionErrorResponse(error)
			console.error(error)
			posthog.capture("deck_sharing:failed", {share_id: shareId, reason: errorObject.message})
			return
		}
		posthog.capture("deck_sharing:claimed", {share_id: shareId})
	}
});


onUnmounted(() => {
	window.removeEventListener('resize', windowSizeStore.updateIsDesktop)
	window.removeEventListener('unhandledrejection', handleUnhandledPromises)
})

onBeforeMount(() => {
	checkNavbarAndHeaderVisibility(router.currentRoute.value.path)
})

posthog.onFeatureFlags(function () {
	if (userStore.user?.seen_welcome_message === false && posthog.isFeatureEnabled('onboarding-flow')) {
		const variant = posthog.getFeatureFlag('onboarding-flow')
		if (variant === 'tour') {
			windowSizeStore.tutorialActive = true
		}
		if (variant === 'constrained_onboarding') {
			windowSizeStore.constrainedOnboardingActive = true
		}
	}
})

watch(
	() => route.hash,
	(hash) => {
		if (hash.startsWith('#access_token')) {
			const url = new URL(window.location.href);
			url.hash = '';
			history.replaceState(null, '', url.toString());
		}
	},
	{ immediate: true }
)


function setLocale() {
	if (userStore.user) {
		locale.value = userStore.user.locale
		localStorage.setItem('locale', userStore.user.locale)
		return
	}
	const lsLocale = localStorage.getItem('locale')
	if (lsLocale) {
		locale.value = lsLocale
	}
}

const checkNavbarAndHeaderVisibility = (path:string) => {
	showNavbarAndHeader.value = !notShowingNavbarAndHeaderPaths.some((notShownPath) =>
		path.startsWith(notShownPath)
	)
}

watch(
	() => router.currentRoute.value.path,
	(newPath) => {
		checkNavbarAndHeaderVisibility(newPath)
	}
)

async function rateDeck() {
	const deckNode = deckStore.newDeckTree.findBFS(deckStore.feedbackDeckId);

	if (!deckNode) {
		const errorMessage = `Deck with ID ${deckStore.feedbackDeckId} not found.`;
		const error = new Error(errorMessage);
		captureException(error, {
			data: {
				"deck_id": deckStore.feedbackDeckId
			}
		});
		toast.error(t('message.errorCodes.general.deckRatingFailed'));
		return;
	}

	const ratingResponse = deckNode.data.rateDeck(deckRating.value, deckFeedback.value);

	if (ratingResponse.error) {
		console.error(ratingResponse.error);
		const errorMessage = `Failed to rate deck: ${ratingResponse.error.message || 'Unknown error occurred'}`;
		const error = new Error(errorMessage);
		captureException(error, {
			data: {
				"originalError": JSON.stringify(ratingResponse.error),
				"deck_id": deckStore.feedbackDeckId
			}
		});
		toast.error(t('message.errorCodes.general.deckRatingFailed'));
		return;
	}

	deckStore.setFeedbackDeckId(null);
	deckRating.value = 10;
	deckFeedback.value = '';
}

const updateSelectedOption = (newOption:number) => {
	deckRating.value = newOption
}
</script>

<template>
	<PromoterScorePopup v-if="userStore.askForPromoterScoreRating" />
	<GenericPopup
		:show-popup="deckStore.feedbackDeckId !== null"
		:submit-action="rateDeck"
		:cancel-action="() => deckStore.setFeedbackDeckId(null)"
		title="Feedback"
		:cancel-text="$t('message.deckFeedback.cancel')"
		:submit-text="$t('message.deckFeedback.submit')"
		@hide-popup="() => deckStore.setFeedbackDeckId(null)"
	>
		<p>{{ $t('message.deckFeedback.welcome') }}</p>
		<RatingScale
			:selected-option="deckRating"
			:update-selected-option="updateSelectedOption"
			:tooltip-good="$t('message.deckFeedback.excellent')"
			:tooltip-bad="$t('message.deckFeedback.unusable')"
		/>
		<p>{{ $t('message.deckFeedback.additionalFeedback') }}</p>
		<textarea
			id=""
			v-model="deckFeedback"
			name=""
			class="feedback-text-area"
		/>
	</GenericPopup>
	<FeedbackPopup v-if="windowSizeStore.displayFeedback" />
	<ReferralPopup
		v-if="windowSizeStore.displayReferral"
		@close-referral="windowSizeStore.toggleReferral"
	/>
	<SettingsPanel :show-if="userStore.user !== null && windowSizeStore.displaySettings" />
	<div
		v-if="['/signup', '/preview', '/signup_email'].includes(router.currentRoute.value.path) || maintenanceMode || !windowSizeStore.globalLoading && userStore.user"
		class="top-level-wrapper"
	>
		<LeftNavbar v-if="windowSizeStore.isDesktop && showNavbarAndHeader" />
		<span
			v-if="windowSizeStore.isDesktop"
			class="desktop-top-level-wrapper"
		>
			<MainHeader
				v-if="showNavbarAndHeader"
				@open-settings="windowSizeStore.toggleSettings"
			/>
			<div :class="showNavbarAndHeader ? 'desktop-router-container' : 'no-nav'">
				<ErrorBoundary>
					<router-view />
				</ErrorBoundary>
			</div>
		</span>
		<div
			v-else
			id="page-wrapper"
			class="mobile-top-level-wrapper"
		>
			<MobileNavbar v-if="showNavbarAndHeader" />
			<ErrorBoundary>
				<router-view />
			</ErrorBoundary>
		</div>
	</div>
	<LoadingState v-else />
</template>

<style scoped>
.no-nav {
	height: 100vh;
	overflow-y: auto;
}

@media (max-width: 768px) {
	.no-nav {
		height: auto;
		min-height: 100vh;
		overflow-y: scroll;
	}
}

.desktop-router-container {
	padding-left: 1rem;
	height: calc(100vh - 92px);
	overflow-y: scroll;
}

.desktop-top-level-wrapper {
	width: 100%;
	height: 100%;
}

.mobile-top-level-wrapper {
	width: 100%;
	max-width: 100%;
	height: 100vh;
	overflow: auto;
}

.top-level-wrapper {
	height: 100%;
	padding: 0;
	background-color: #fbf8fc;
	display: flex;
	width: 100%;
}

.sub-slider-container {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin: 2rem 0rem;
}

.feedback-text-area {
	width: 100%;
	padding: 0.5rem;
	border-radius: 5px;
	border: 1px solid #f1e8f4;
	font-size: 1rem;
	margin-bottom: 0.5rem;
	height: 8rem;
	resize: none;
	color: #666;
}
</style>