import { supabase } from '../common/database'

export const UserService = {
	async signOut() {
		return await supabase.auth.signOut()
	},
	async fetchUser() {
		return await supabase.from('profiles').select('*')
	},
	subscribeToChanges(callback) {
		return supabase
			.channel('profiles')
			.on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'profiles' }, (payload) =>
				callback(payload)
			)
			.subscribe()
	},
	async setSeenWelcomeMessage(userId) {
		return await supabase.from('profiles').update({ seen_welcome_message: true }).eq('id', userId)
	},
	async setSeenSpacedRepetitionPopup(userId) {
		return await supabase
			.from('profiles')
			.update({ seen_spaced_repetition_popup: true })
			.eq('id', userId)
	},
	async updateUserLocale(newLocale, userId) {
		if (!['en', 'de'].includes(newLocale)) return
		return await supabase.from('profiles').update({ locale: newLocale }).eq('id', userId)
	},
	async uploadNewAvatar(userId, newAvatar, now) {
		return await supabase.storage.from('avatars').upload(`${userId}/${now}-avatar.png`, newAvatar, {
			cacheControl: '3600',
			upsert: true,
		})
	},
	getAvatarPublicUrl(authId, now) {
		return supabase.storage.from('avatars').getPublicUrl(`${authId}/${now}-avatar.png`)
	},
	async setNewAvatar(avatarUrl, userId) {
		return await supabase.from('profiles').update({ avatar_url: avatarUrl }).eq('id', userId)
	},
	async updateDisplayName(newName, userId) {
		return await supabase.from('profiles').update({ name: newName }).eq('id', userId)
	},
	async setNpsFlagFalse(userId) {
		return await supabase.from('profiles').update({ show_nps_popup: false }).eq('id', userId)
	},
	async setOnboardingCompleted(userId) {
		return await supabase.from('profiles').update({ show_onboarding_tour: false }).eq('id', userId)
	},
}
