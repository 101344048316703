<script lang="ts" setup>
import GenericButton from '@/components/common/GenericButton.vue';
import { toast } from 'vue3-toastify';
import { supabase } from '@/common/database';
import GenericPopup from '@/components/common/GenericPopup.vue';
import { useUserStore, useWindowSizeStore } from '@/store/store';
import { useI18n } from 'vue-i18n';
import { Ref, ref } from 'vue';
import { useRouter } from 'vue-router';

const { t } = useI18n()
const router = useRouter()
const { locale } = useI18n()
const userStore = useUserStore()
const windowSizeStore = useWindowSizeStore()

const reason:Ref<string> = ref("")
const showDeleteAccountPopup:Ref<boolean> = ref(false)
function handleCancelDeleting() {
	showDeleteAccountPopup.value = false
}
function handleOpenDeleteAccountPopup() {
	showDeleteAccountPopup.value = true
}
async function handleDeleteAccount() {
	if (reason.value === '') {
		toast('Could you please tell us why you are quitting Studyflash?')
		return
	}
	const emailResponse = await supabase.functions.invoke('delete-account', {
		body: {
			reason: reason.value,
			locale: locale.value
		},
	})
	if (emailResponse.error) {
		toast(t('message.errorCodes.settings.accountDeleteFailed'))
		return
	}
	toggleSettings()
	userStore.signOut(router)
}
function toggleSettings() {
	windowSizeStore.toggleSettings()
}
</script>

<template>
	<div>
		<GenericPopup
			:show-popup="showDeleteAccountPopup"
			:submit-action="handleDeleteAccount"
			:cancel-action="handleCancelDeleting"
			:submit-text="$t('message.settings.delete')"
			:cancel-text="$t('message.settings.discard')"
			:popup-styles="{ desktop: { zIndex: 9 }, mobile: { zIndex: 9 } }"
			:title="$t('message.settings.deleteAccount')"
			@hide-popup="handleCancelDeleting"
		>
			<div class="flex flex-col gap-y-6">
				<div class="flex flex-col gap-y-3">
					<p class="m-0">
						{{ $t('message.settings.deleteAccountConfirmation1') }}
					</p>
					<p class="m-0">
						{{ $t('message.settings.deleteAccountConfirmation2') }}
					</p>
					<p class="m-0">
						Please tell us why:
					</p>
				</div>
				<textarea
					v-model="reason"
					class="w-full border-std text-sf-gray-200 resize-none text-base min-h-40"
					aria-multiline="true"
				/>
			</div>
		</GenericPopup>
		<div class="flex flex-col gap-6">
			<h2 class="text-2xl text-sf-gray-200 font-bold m-0">
				{{ $t('message.settings.deleteAccount') }}
			</h2>
			<span>
				<p class="text-lg text-sf-gray-100 p-0 m-0">{{ $t('message.settings.deleteAccountText') }}</p>
				<p class="text-lg text-sf-gray-100 p-0 m-0">{{ $t('message.settings.deleteAccountText1') }}</p>
			</span>
			<GenericButton
				type="primary"
				:on-click="handleOpenDeleteAccountPopup"
				:text="$t('message.settings.deleteMyAccount')"
				style="width: max-content;"
			/>
		</div>
	</div>
</template>

<style scoped>
</style>
