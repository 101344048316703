<script setup lang="ts">
import { useTokenBalance } from '@/services/transaction.service';

const { tokenBalance } = useTokenBalance()

</script>

<template>
	<div class="flex flex-col gap-6">
		<h1 class="text-2xl text-sf-gray-200 font-bold m-0">
			{{ $t('message.settings.tokensCaps') }}
		</h1>
		<p class="text-lg text-sf-gray-200 font-semibold p-0 m-0 leading-10">
			{{ $t('message.settings.balance') }}:&nbsp;&nbsp;
			<span
				class="text-lg text-studyflash-800 font-semibold m-0 leading-10"
			>
				{{ tokenBalance }} {{ $t('message.settings.tokens') }}
			</span>
		</p>
		<p class="text-base text-sf-gray-100 m-0 p-0">
			{{ $t('message.settings.legacyTokensText') }}
		</p>
	</div>
</template>


<style scoped>

</style>
