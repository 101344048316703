import { createClient, RealtimeChannel } from '@supabase/supabase-js'
import { Database } from '@/types/database.types'

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY

const customFetchWithRetry = async (
	url: URL | RequestInfo,
	options: RequestInit | undefined,
	retries = 3,
	cooldown = 100
): Promise<Response> => {
	let response = await fetch(url, options)
	for (let i = 0; i < retries; i++) {
		try {
			if (response.ok) {
				return response
			}
			response = await fetch(url, options)

			if (response.status >= 400 && response.status < 500) {
				console.error(`Client error: ${response.status} ${response.statusText}`)
				return response
			}
			if (response.status >= 500 && response.status < 600) {
				console.error(`Internal server error: ${response.status} ${response.statusText}`)
				return response
			}

			console.error(`Attempt ${i + 1} failed: ${response.statusText}`)
		} catch (error) {
			if (error instanceof Error) {
				console.error(`Attempt ${i + 1} failed:`, error.message)
			} else {
				console.error(`Attempt ${i + 1} failed:`, error)
			}

			console.error(url.toString())
			console.error(url)
		}
		if (i < retries - 1) {
			await new Promise((resolve) => setTimeout(resolve, cooldown))
		}
	}
	console.error(`Failed to fetch: ${url}`)
	return response
}

export function removeRealtimeSubscription(subscriptionObject: RealtimeChannel) {
	supabase.removeChannel(subscriptionObject)
}

export const supabase = createClient<Database>(supabaseUrl, supabaseAnonKey, {
	global: {
		fetch: (url, options) => customFetchWithRetry(url, options),
	},
})
