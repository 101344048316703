import { useQuery } from '@tanstack/vue-query'
import { supabase } from '@/common/database'
import { Tables } from '@/types/database.types'

async function fetchInitialData(): Promise<Tables<'transactions'>[]> {
	const { data, error } = await supabase.from('transactions').select('*')
	if (error) {
		throw new Error('Could not fetch transactions')
	}
	return data
}

export function useTokenBalance() {
	const calculateTokenBalance = (data: Tables<'transactions'>[]) => {
		return data.reduce((acc, t) => {
			if (!t.payment_type || !['subscription', 'trial_deck'].includes(t.payment_type)) {
				return acc + t.tokens
			}
			return acc
		}, 0)
	}

	const { data, error, isLoading, isError, refetch } = useQuery({
		queryKey: ['transactions'],
		queryFn: fetchInitialData,
		select: calculateTokenBalance,
	})

	return {
		tokenBalance: data,
		isLoading,
		isError,
		error,
		refetch,
	}
}
