import { supabase } from "../common/database"

export const submitPromoterScore = async (promoterScoreDTO) => {
    return await supabase.from('promoter_score').insert(promoterScoreDTO)
}

export const checkIfRatingExists = async () => {
    const response = await supabase.from('promoter_score').select('*')
    if (response.data.length === 0) return false
    return true
}