<script setup lang="ts">
import { useUserStore, useWindowSizeStore } from '@/store/store'
import { ArrowLeft02Icon } from '@hugeicons/vue'
import { ref } from 'vue'
import GenericButton from '../common/GenericButton.vue'
import SubscriptionTab from './SettingsPanelSubcomponents/SubscriptionTab.vue'
import AccountTab from './SettingsPanelSubcomponents/AccountTab.vue'
import TokenTab from './SettingsPanelSubcomponents/TokenTab.vue'
import MoreSettingsTab from './SettingsPanelSubcomponents/MoreSettingsTab.vue'

const pages = ['account', 'tokens', 'subscription', 'more']
const windowSizeStore = useWindowSizeStore()
const userStore = useUserStore()

const currentPage = ref('account')

const props = defineProps({
	showIf: {
		type: Boolean,
		required: true
	}
})

function closeSettings() {
	windowSizeStore.toggleSettings()
}
function goBack() {
	windowSizeStore.toggleSettings()
	windowSizeStore.showMobileNavbar = true
}

function shouldShowTab(page:string) {
	if (page === 'tokens' && userStore.user && userStore.user.subscription_user ) {
		return false
	}
	return true
}
</script>

<template>
	<Transition name="fade">
		<div
			v-if="props.showIf && windowSizeStore.isDesktop"
			id="settingsBg"
			class="fixed top-0 left-0 h-full w-full bg-black bg-opacity-50 z-[8] opacity-100 transition-opacity duration-1000 ease-out"
			@click="closeSettings"
		/>
	</Transition>
	<Transition name="slide">
		<div
			v-if="props.showIf"
			id="settings"
			class="fixed top-0 right-0  bg-white z-[9] h-full w-full md:max-w-[50%] md:w-[500px] lg:w-[600px]"
			@click.stop
		>
			<div class="flex gap-6 flex-col p-6 md:p-8">
				<div class="flex items-center justify-between w-full">
					<ArrowLeft02Icon
						v-if="!windowSizeStore.isDesktop"
						type="standard"
						variant="stroke"
						:size="24"
						@click="goBack"
					/>
					<p
						class="m-0 text-2xl text-sf-gray-200 font-semibold"
					>
						{{ $t('message.settings.settings') }}
					</p>
					<GenericButton
						type="secondary"
						:text="$t('message.settings.closeButton')"
						:on-click="closeSettings"
						:style="windowSizeStore.isDesktop ? { padding: '11px 24px' } : { border: 'none' }"
					/>
				</div>
				<div
					class="flex bg-white justify-between text-sf-gray-100"
					style=" box-shadow: inset 0 -1px 0 0 #f1e8f4;"
				>
					<ul class="p-0 m-0 flex w-full list-none">
						<span
							v-for="(page, index) in pages"	
							:key="index"
							class="flex-1 rounded-lg md:flex-[0]"
							:style="!windowSizeStore.isDesktop && { flex: '1', borderRadius: '8px' }"
						>
							<li
								v-if="shouldShowTab(page)"
								:class="{ 'text-studyflash-800 font-semibold border-b-studyflash-800 border-0 border-b-2 border-solid': currentPage === page }"
								class="p-3 cursor-pointer text-base text-center hover:bg-[#f1e8f4]"
								@click.stop="currentPage = page"
							>
								{{ $t(`message.settings.${page}`) }}
							</li>
						</span>
					</ul>
				</div>
				<div class="flex-1">
					<AccountTab v-if="currentPage === 'account'" />
					<TokenTab v-if="currentPage === 'tokens'" />
					<SubscriptionTab v-if="currentPage === 'subscription'" />
					<MoreSettingsTab v-if="currentPage === 'more'" />
				</div>
			</div>
		</div>
	</Transition>
</template>

<style scoped>
/* Slide transition */
.slide-enter-active,
.slide-leave-active {
	transition: transform 0.5s ease;
}

.slide-enter-from {
	transform: translateX(300%);
}

.slide-enter-to {
	transform: translateX(0);
}

.slide-leave-from {
	transform: translateX(0);
}

.slide-leave-to {
	transform: translateX(300%);
}

/* Fade transition */
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
	opacity: 1;
}
</style>
