<script>
import UserInfo from './UserInfo.vue';
import CONSTANTS from '@/common/constants.js'
import GenericButton from '../common/GenericButton.vue';
import { Rocket01Icon } from '@hugeicons/vue';

export default {
	components: { UserInfo, GenericButton },
	emits: ['open-settings'],
	setup() {
		return {
			Rocket01Icon
		}
	},
	data() {
		return {
			showHeader: false,
			featureOsUrl: CONSTANTS.FEATURE_OS_URL,
			headerStyle: {
				height: CONSTANTS.DESKTOP_HEADER_HEIGHT + 'px'
			}
		}
	},
	methods: {
		openSettings() {
			this.$emit('open-settings');
		}
	}
};
</script>

<template>
	<div
		id="header"
		class="header"
		:style="headerStyle"
	>
		<a
			:href="featureOsUrl"
			target="_blank"
			style="text-decoration: none;"
		>
			<GenericButton
				type="secondary"
				:text="$t('message.header.seeWhatsNew')"
				style="padding: 8px 24px; height: 100%;"
				:icon="{ component: Rocket01Icon, props: { type: 'rounded', variant: 'solid', size: '24' } }"
			/>
		</a>
		<UserInfo @open-settings="openSettings" />
	</div>
</template>

<style>
.feature-os-button {
	border-radius: 8px;
	border: 1px solid #f1e8f4;
	color: #7a219e;
	padding: 12px 24px;
	height: 100%;
	font-weight: 700;
	font-size: 14px;
	line-height: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.feature-os-button:hover {
	background-color: #f6f1f8;
}

.icon {
	height: 24px;
}

.header {
	justify-content: flex-end;
	gap: 16px;
}
</style>
