import { supabase } from "../common/database";
import { v4 as uuidv4 } from 'uuid';

export const FlashcardService = {
    async createNewFlashcard(deckId, userId, question, answer) {
        const cardData = {
            id: uuidv4(),
            question: question,
            answer: answer,
            deck_id: deckId,
            created_by: userId,
        }
        const response = await supabase.from('flashcards').insert(cardData).select()
        response.cardId = cardData.id
        return response
    },
    async getDecksFlashcards(deckId) {
        const response = await supabase.from('flashcards').select("*").filter('deck_id', 'eq', deckId).filter('_deleted', 'eq', false)
        return response.data
    },
    async fetchFlashcardsWithOffset(from, to, deckIdsArray) {
        return await supabase.from('flashcards').select('*')
            .in('deck_id', deckIdsArray)
            .eq('_deleted', false)
            .order('chunk_order_id', { ascending: true, nullsFirst: true })
            .order('chunk_order_id', { ascending: true })
            .order('order_id', { ascending: true })
            .order('position', { ascending: true })
            .range(from, to)
    },
    async searchForFlashcards(searchParam, deckIdsArray) {
        return await supabase.from('flashcards').select('*')
            .in('deck_id', deckIdsArray)
            .eq('_deleted', false)
            .or(`question.ilike.%${searchParam}%,answer.ilike.%${searchParam}%`)
            .order('chunk_order_id', { ascending: true, nullsFirst: true })
            .order('chunk_order_id', { ascending: true })
            .order('order_id', { ascending: true })
            .order('position', { ascending: true })
    },
    async updateFlashcard(newState) {
        return await supabase.from('flashcards').update(newState).eq('id', newState.id)
    },
    async deleteCard(cardId) {
        return await supabase.from('flashcards').update({ '_deleted': true }).eq('id', cardId)
    }
}