<script setup lang="ts">
import { Ref, ref } from 'vue';
import EN from '@/assets/english.png';
import DE from '@/assets/german.png';
import { ArrowDown01Icon } from '@hugeicons/vue';
import { useI18n } from 'vue-i18n';
import { useUserStore } from '@/store/store';
import { toast } from 'vue3-toastify';

interface Language {
    value: 'en' | 'de',
    label: string,
    image: string
}

const { locale, t } = useI18n()
const userStore = useUserStore()

const selectedLanguage: Ref<'en' | 'de'> = ref('en');
const isOpen: Ref<boolean> = ref(false);
const languages: Language[] = [
	{ value: 'en', label: 'English', image: EN },
	{ value: 'de', label: 'Deutsch', image: DE },
];

const handleSelect = async (newLocale: 'en' | 'de') => {
	if (locale.value === newLocale) return
	selectedLanguage.value = newLocale
	if(userStore.user){
		const response = await userStore.changeLocale(newLocale)
		if (!response) return
		if (response.error) {
			toast.error(t('message.errorCodes.settings.localeChangeFailed'))
			return
		}  
	} 
	locale.value = newLocale
	toggleDropdown()
};

const toggleDropdown = () => {
	isOpen.value = !isOpen.value;
};
</script>

<template>
	<div class="relative inline-block text-left">
		<button
			type="button"
			class="border-std flex items-center px-4 py-3 min-w-[12rem] max-w-[90%] justify-between"
			aria-haspopup="true"
			:aria-expanded="isOpen"
			@click="toggleDropdown"
		>
			<div class="flex justify-center items-center">
				<img
					:src="selectedLanguage === 'en' ? EN : DE" 
					class="h-6 w-6 rounded-full mr-2"
				>
				<span>{{ selectedLanguage === 'en' ? 'English' : 'Deutsch' }}</span>
			</div>
			<ArrowDown01Icon
				type="standard"
				variant="stroke"
				color="black"
				:size="24"
			/>
		</button>
		<div 
			v-if="isOpen"
			class="absolute w-max z-10 bg-white rounded-md shadow-lg min-w-[12rem] max-w-[90%]"
		>
			<ul class="m-0 p-0 w-full">
				<li
					v-for="language in languages"
					:key="language.value"
					class="list-none w-full flex items-center cursor-pointer px-4 py-2 text-left hover:bg-gray-100"
					@click="handleSelect(language.value)"
				>
					<img
						:src="language.image"
						class="h-6 w-6 rounded-full mr-2"
					>
					<span>{{ language.label }}</span>
				</li>
			</ul>
		</div>
	</div>
</template>
