<script lang="ts" setup>
import { useSubscriptionStore } from '../../../store/store'
import { supabase } from '../../../common/database'
import { toast } from 'vue3-toastify'
import { captureException } from "@sentry/vue";
import GenericButton from '@/components/common/GenericButton.vue';
import { computed, ref, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Subscription } from '@/data_structures/Subscription';

const { t } = useI18n()
const subscriptionStore = useSubscriptionStore()
const loading:Ref<boolean> = ref(false)

async function navigateToStripe() {
	loading.value = true
	const { data: customerPortalSession, error } = await supabase.functions.invoke('customer-portal')
	loading.value = false
	if (error) {
		console.error(error)
		const errorMessage = `Navigation to manage Subscriptions failed ${error.message}`
		captureException(new Error(errorMessage))
		toast.error(t('message.errorCodes.settings.manageSubscriptionsFailed'))
		return
	}
	window.location.href = customerPortalSession.portalSessionUrl
}

const subscriptionType = computed(() => {
	if(!subscriptionStore.subscription) return t(`message.settings.inactive`)
	const subscription:Subscription = subscriptionStore.subscription
	if (subscription && (subscriptionStore.getSubscriptionStatus() === 2 || subscriptionStore.getSubscriptionStatus() === 5)) {
		return t(`message.settings.${subscription.type}`)
	}
	return t(`message.settings.inactive`)
})

const endDate = computed(() => {
	if(!subscriptionStore.subscription) return ""
	const subscription:Subscription = subscriptionStore.subscription
	return new Date(subscription.end_date).toLocaleDateString()
})

</script>

<template>
	<div class="flex flex-col gap-6">
		<h1 class="text-2xl text-sf-gray-200 font-bold m-0">
			{{ $t('message.settings.subscriptionTitle') }}
		</h1>
		<div>
			<p class="text-lg text-sf-gray-200 font-semibold p-0 m-0">
				{{ $t('message.settings.currentSubscription') }}: <span class="text-lg text-studyflash-800 font-semibold m-0">
					{{ subscriptionType }}
				</span>
			</p>
			<p
				v-if="subscriptionStore.getSubscriptionStatus() === 2 || subscriptionStore.getSubscriptionStatus() === 5"
				class="text-lg text-sf-gray-200 font-semibold p-0 m-0"
			>
				{{ $t('message.settings.renewalDate') }}: <span class="text-lg text-studyflash-800 font-semibold m-0">
					{{
						endDate
					}}
				</span>
			</p>
		</div>
		<GenericButton
			type="primary"
			:on-click="navigateToStripe"
			:disabled="loading"
			:text="$t('message.settings.manageSubscriptions')"
			style="width: max-content;"
		/>
	</div>
</template>

<style scoped>
.sub-tab-wrapper {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.settings-title {
	color: #4a5361;
	font-size: 24px;
	line-height: 32px;
	font-weight: 700;
	margin: 0;
}

.info {
	color: #7a219e;
	font-weight: 500;
}

.label {
	font-size: 16px;
	color: #4a5361;
	font-weight: 600;
	margin: 0;
}

.tab-title {
	font-size: 1.4rem;
	font-weight: 600;
	line-height: 1.43;
	color: #4a5361;
}

.manage-sub-button {
	background-color: #7a219e;
	color: white;
	font-weight: 700;
	font-size: 14px;
	padding: 8px 16px;
	border-radius: 8px;
	margin-top: auto;
}

.manage-sub-button:hover {
	background: #954cb1;
}
</style>
