<script>
import { useWindowSizeStore } from '../../store/store';

export default {
	name: "RatingScale",
	props: {
		selectedOption: {
			type: Number,
			required: true
		},
		updateSelectedOption: {
			type: Function,
			required: true
		},
		tooltipBad: {
			type: String,
			required: true
		},
		tooltipGood: {
			type: String,
			required: true
		}
	},
	setup() {
		const windowSizeStore = useWindowSizeStore()
		return {
			windowSizeStore
		}
	},
	data() {
		return {
			options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
		}
	},
	methods: {
		getClass(option) {
			let baseClass = this.windowSizeStore.isDesktop ? 'scale-option' : 'scale-option-mobile';
			let stateClass = this.selectedOption === option ? 'active' : 'inactive';
			return `${baseClass} ${stateClass}`;
		}
	}
}
</script>

<template>
	<div class="scale-container">
		<div
			v-for="(option, index) in options"
			:key="index"
			:class="getClass(option)"
			@click="() => updateSelectedOption(option)"
		>
			{{ option }}
		</div>
	</div>
	<div class="tooltip-container">
		<div class="tooltip">
			{{ tooltipBad }}
		</div>
		<div class="tooltip">
			{{ tooltipGood }}
		</div>
	</div>
</template>

<style scoped>
.tooltip-container {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
}

.scale-container {
    justify-content: center;
    display: flex;
    width: 100%;
    gap: 0.2rem;
}

.scale-option {
    display: grid;
    place-items: center;
    flex: 1;
    padding: 1rem 0.2rem;
    border: 1px solid white;
    border-radius: 8px;
    cursor: pointer;
    box-sizing: border-box;
    max-width: 3rem;
}

.scale-option-mobile {
    display: grid;
    place-items: center;
    flex: 1;
    padding: 1rem 0.2rem;
    border: 1px solid white;
    border-radius: 8px;
    cursor: pointer;
    box-sizing: border-box;
}

.inactive {
    color: white;
    background-color: #7F3EA0
}

.active {
    background-color: #fefefe;
    color: #7F3EA0;
    border: 1px solid #7F3EA0;
}
</style>